import { createIncident, useOperator } from "common"
import { Modal, Stack, TextInput, Button } from "components"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const CreateRemoteVisitIncident = () => {
  const { t } = useTranslation()
  const operator = useOperator()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [incidentCode, setIncidentCode] = useState("")

  const createNewIncident = async () => {
    try {
      incidentCode && (await createIncident(incidentCode))
      setIsModalOpen(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  return (
    <>
      <Button
        tabIndex={-1}
        hotkey="Ctrl+0"
        variant="secondary"
        onClick={() => setIsModalOpen(true)}
      >
        {t("homepage.addRemoteVisitIncident", "Add remote visit incident")}
      </Button>
      {isModalOpen && (
        <Modal
          id="CreateIncidentModal"
          header={t("homepage.addRemoteVisitIncident", "Add remote visit incident")}
          onClose={() => setIsModalOpen(false)}
        >
          <Stack fullWidth vertical spacing="12px">
            <TextInput
              fullWidth
              autoFocus
              id="incidentCode"
              label={t("createIncidentModal.incidentCode", "Incident Code")}
              onChange={(value) => setIncidentCode(value)}
            />
            <Stack fullWidth spacing="12px">
              <Button
                hotkey="n"
                id="CreateNewIncidentBtn"
                variant="secondary"
                onClick={() => setIsModalOpen(false)}
              >
                {t("general.cancel", "Cancel")}
              </Button>
              <Button
                disabled={!incidentCode || !operator.personnelId}
                hotkey="Ctrl+s"
                id="CreateNewIncidentBtn"
                variant="primary"
                onClick={() => createNewIncident()}
              >
                {t("homepage.addIncident", "Add incident")}
              </Button>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  )
}
