import { Box, ColorName } from "components"
import { ReactNode } from "react"
import { IconName } from "../icons"
import { Stack } from "../layout/Stack"
import { Text } from "../typography/Text"
import { Icon } from "./Icon"

interface informationProps {
  /** Information to be displayed in text field */
  children?: ReactNode
  /** Name of the Icon to be displayed next to the header */
  icon: IconName
  /** Text to be displayed in the header*/
  heading: string
  /** Maximum width of the information panel */
  maxWidth?: string
  /** Color of the text heading */
  color?: ColorName
  /** Id for playwright test */
  id?: string
}

/**
 * Information component that renders a header with an icon, and a text field
 */
export const Information = ({ children, icon, heading, maxWidth, color, id }: informationProps) =>
  children ? (
    <Box maxWidth={maxWidth}>
      <Stack spacing="13px">
        <Icon size="20px" color={color || "greyT15"} name={icon} />
        <Stack spacing="6px" vertical>
          <Text color={color || "grey"} variant="h3">
            {heading}
          </Text>
          <Text id={id} color={color || "black"}>
            {children}
          </Text>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <></>
  )
