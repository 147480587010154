import { createContext, ReactNode, useContext } from "react"
import { useHotkeys as useHotkeysReact } from "react-hotkeys-hook"
import { usePersistentState } from "./usePersistentState"

interface HotKeysContextProps {
  showHelp: boolean
  toggleShowHelp: () => void
}

/**
 * A context used to keep track of disabled hotkeys
 */
const HotKeysContext = createContext<HotKeysContextProps>({
  showHelp: false,
  toggleShowHelp: () => {},
})

/**
 * This provides the context needed for the useHotkeys-hook
 * to know when a keyboard shortcut is disabled.
 */
export const HotkeysProvider = ({ children }: { children: ReactNode }) => {
  const [showHelp, setShowHelp] = usePersistentState<boolean>("showHotkeysHelp", false)
  const toggleShowHelp = () => setShowHelp(!showHelp)
  return (
    <HotKeysContext.Provider value={{ showHelp, toggleShowHelp }}>
      {children}
    </HotKeysContext.Provider>
  )
}

/**
 * Add a keyboard shortcut (like `ctrl+h`) when mounting a component.
 * Will prevent default automatically
 */
export const useHotkeys = (
  keys: string,
  callback: () => void,
  deps?: unknown[],
  disabled?: boolean
) => {
  useHotkeysReact(
    keys,
    callback,
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"], // Enable hotkeys in input fields
      filter: (e) => {
        e.preventDefault()
        return !disabled
      },
    },
    [deps, disabled]
  )
}

export const useShowHotkeysHelp = () => {
  const { showHelp, toggleShowHelp } = useContext(HotKeysContext)
  return { showHelp, toggleShowHelp }
}
