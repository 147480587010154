import { QueueIncident } from "common"
import { Box, Stack } from "components"
import { AlarmPriority } from "features"
import { memo } from "react"
import { Incident } from "./Incident"

interface QueueProps {
  /** List of incidents */
  incidentList: QueueIncident[]
  /** Toggle's sidebar full width or minimized view */
  isOpen: boolean
}
/**
 * This component renders the Queue, which contains the list of incidents.
 */
export const Queue = memo(({ incidentList, isOpen }: QueueProps) => {
  return (
    <Box id="Queue" alignItems="start" height="100%" padding="0 20px" scroll="vertical">
      <Stack spacing="27px" fullWidth vertical>
        {incidentList?.map((incident) => {
          return (
            <Incident
              priority={incident.priority as AlarmPriority}
              minimized={!isOpen}
              key={incident.id}
              incidentType={incident.typeDescription}
              date={new Date(incident.createdTime)}
              department={incident.departmentName}
              recipient={`${incident.careRecipients[0].name}`}
              sla={incident.ongoingSla}
            />
          )
        })}
      </Stack>
    </Box>
  )
})
