import { css } from "@emotion/css"
import { useHotkeys } from "common"
import { Box, Hotkey, Stack } from "components"
import { ReactNode, useRef, useState } from "react"
import { ColorName, Colors } from "../colors"
import { Text } from "../typography/Text"
import { IconButton } from "./IconButton"

interface CardProps {
  /** What to display in the main content area of the card  */
  children?: ReactNode
  /**
   * What to display in the header area of the card.
   *
   * A string will be rendered as a full header with background and the correct header styling
   * Can also accept any ReactNode which will replace the entire header.
   */
  header?: string | ReactNode
  /**
   * Add an ID to the div element.
   */
  id?: string
  /**
   *  The width of the card.
   */
  width?: string
  /**
   * Set a minimum width for the card.
   */
  minWidth?: string
  /**
   * Make the card take upp 100% of the horizontal space
   */
  fullWidth?: boolean
  /** Background of the main content area of the card */
  background?: ColorName
  /** Remove the padding of both the content and the header area */
  noPadding?: boolean
  /** Allow the card to grow */
  grow?: boolean
  /** Whether the card is able to be collapsed.
   *
   * If true, use the `collapseHotkeyPattern` prop to set a hotkey for toggling
   * the collapsed state.
   */
  collapsible?: boolean
  /** Hotkey pattern that toggles the collapsed state of the card */
  collapseHotkeyPattern?: string
  /** Height of the main content box  */
  contentHeight?: string
  /** Hotkey pattern for focusable card */
  focusHotkeyPattern?: string
  /** Minimum height of the card */
  minHeight?: string
}

/**
 * A simple card component.
 */
export const Card = ({
  header,
  children,
  id,
  width,
  fullWidth,
  minWidth,
  background,
  noPadding,
  grow,
  collapsible = false,
  collapseHotkeyPattern,
  contentHeight,
  focusHotkeyPattern,
  minHeight,
}: CardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const focusCard = () => {
    if (containerRef.current) {
      containerRef.current.focus()
    }
  }

  const headerStyle = css`
    width: 100%;
    border-radius: ${isCollapsed ? "6px" : "6px 6px 0 0"};
    border-bottom: ${isCollapsed ? `0px` : `1px`} solid ${Colors.greyT15};
    padding: ${noPadding ? 0 : "20px"};
    background: ${Colors.aliceBlue};
    min-width: ${minWidth || undefined};
    box-sizing: border-box;
  `

  useHotkeys("down", () => {
    const activeElement = document.activeElement
    const theCard = containerRef.current

    if (!theCard || !activeElement) {
      return
    }

    if (activeElement === theCard) {
      const nextChild = activeElement.querySelectorAll("[role='button']")[0]
      return nextChild instanceof HTMLElement && nextChild.focus()
    }

    if (theCard.contains(activeElement) && activeElement.nextSibling instanceof HTMLElement) {
      return activeElement.nextSibling.focus()
    }
  })

  useHotkeys("up", () => {
    const activeElement = document.activeElement
    const theCard = containerRef.current

    if (!theCard || !activeElement) {
      return
    }

    if (activeElement === theCard) {
      return
    }

    if (theCard.contains(activeElement) && activeElement.previousSibling instanceof HTMLElement) {
      return activeElement.previousSibling.focus()
    }
  })

  return (
    <Box
      boxRef={containerRef}
      tabIndex={focusHotkeyPattern ? 0 : undefined}
      grow={grow}
      dontShrink
      alignItems="stretch"
      width={fullWidth ? "100%" : width}
      vertical
      id={id}
      borderColor="greyT15"
      borderWidth="1px"
      radius="6px"
      background={background || "white"}
      height="auto"
      hideOverflow={!isCollapsed}
      minHeight={minHeight}
    >
      {header && (
        <div className={headerStyle}>
          <Stack justifyContent="space-between" alignItems="center">
            {typeof header === "string" ? (
              <Text color="battleShipBlue" variant="h1">
                {header}
              </Text>
            ) : (
              header
            )}
            {collapsible &&
              (noPadding ? (
                <Box padding="20px">
                  <IconButton
                    tabIndex={collapseHotkeyPattern || focusHotkeyPattern ? -1 : undefined}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    name={isCollapsed ? "expand_less" : "expand_more"}
                    color="azure"
                    size="20px"
                    hotkey={collapseHotkeyPattern}
                  />
                </Box>
              ) : (
                <IconButton
                  tabIndex={collapseHotkeyPattern || focusHotkeyPattern ? -1 : undefined}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  name={isCollapsed ? "expand_more" : "expand_less"}
                  color="azure"
                  size="20px"
                  hotkey={collapseHotkeyPattern}
                />
              ))}
            {focusHotkeyPattern && (
              <Hotkey
                color="azure"
                id={`${focusHotkeyPattern}-hotkey`}
                pattern={focusHotkeyPattern}
                callback={focusCard}
              />
            )}
          </Stack>
        </div>
      )}
      <Box
        alignItems="stretch"
        height={isCollapsed ? "0" : contentHeight ? contentHeight : "100%"}
        padding={noPadding || isCollapsed ? "0" : "20px"}
        hideOverflow
        scroll={contentHeight ? "vertical" : "horizontal"}
      >
        {children}
      </Box>
    </Box>
  )
}
