import {
  addNote,
  addReason,
  closeIncident,
  transferIncident,
  useEvents,
  useIncident,
  useIncidents,
} from "common"
import { Box, Confirm } from "components"
import { HandleIncidentCareStaff, HandleIncidentClosed, HandleReason } from "features"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ChooseAction } from "./ChooseAction"

interface HandlingScreenProps {
  /** The incidentId of the incident to handle. */
  incidentId: string
  /** Disables the form */
  disabled?: boolean
  /** The care recipient associated with the incident */
  recipientId: string
  /** Handling screen to show */
  handlingScreen: "handleReason" | "chooseAction" | "handleIncident" | "incidentClosed"
}

/**
 * This component decides which of the handling screens to show (e.g. handleReason, chooseAction, handleIncident, incidentClose).
 * Depending on what actions are chosen, the incident can be transferred to another operator or closed.
 */
export const HandlingScreen = ({
  incidentId,
  recipientId,
  disabled,
  handlingScreen,
}: HandlingScreenProps) => {
  const { t } = useTranslation()
  const { data: incident, mutate: refreshIncident } = useIncident(incidentId)
  const { mutate: refreshIncidentHistory } = useIncidents({ careRecipientId: recipientId })
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [note, setNote] = useState("")
  const { mutate: refreshEvents } = useEvents(incidentId)

  const setNoteAndOpenConfirm = (note: string) => {
    setNote(note)
    setIsConfirmOpen(true)
  }

  const close = async () => {
    setIsLoading(true)
    await closeIncident(incidentId, note)
    refresh()
  }

  const saveReason = async (reasonId: string, note?: string) => {
    setIsLoading(true)
    await addReason(incidentId, reasonId, note)
    refresh()
  }

  const saveNote = async (note: string) => {
    setIsLoading(true)
    note.trim() !== "" && (await addNote(incidentId, note))
    refresh()
  }

  const transfer = async (note?: string) => {
    setIsLoading(true)
    await transferIncident(incidentId, note)
    refresh()
  }

  const refresh = async () => {
    setIsLoading(false)
    refreshIncident()
    refreshEvents()
    refreshIncidentHistory()
  }

  return (
    <Box width="100%" alignItems="center" justifyContent="center">
      {handlingScreen === "handleReason" && (
        <HandleReason isDisabled={disabled || isLoading} callback={saveReason} />
      )}
      {incident && handlingScreen === "chooseAction" && (
        <ChooseAction
          incident={incident}
          addNote={saveNote}
          disabled={disabled || isLoading}
          transferIncident={transfer}
          closeIncidentMessage={setNoteAndOpenConfirm}
        />
      )}
      {incident && handlingScreen === "handleIncident" && (
        <HandleIncidentCareStaff incidentId={incidentId} />
      )}

      {incident && handlingScreen === "incidentClosed" && <HandleIncidentClosed />}

      {isConfirmOpen && (
        <Confirm
          id="CloseIncidentConfirm"
          onClose={() => setIsConfirmOpen(false)}
          title={t("incident.confirm", "Are you really sure ?")}
          onConfirm={close}
        />
      )}
    </Box>
  )
}
