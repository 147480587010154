import { css } from "@emotion/css"
import { ReactNode } from "react"

interface TableHeadProps {
  children: ReactNode
}

const theadStyle = css`
  th {
    padding: 12px 10px;
    background-color: #fff;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0px 7px 16px -7px rgb(0 0 0 / 8%);
    white-space: nowrap;
  }
  tr th:first-child,
  tr th:last-child {
    border-bottom: none;
  }
`

export const TableHead = ({ children }: TableHeadProps) => (
  <thead className={theadStyle}>
    <tr>
      <th />
      {children}
      <th />
    </tr>
  </thead>
)
