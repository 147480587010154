import { css } from "@emotion/css"
import { Colors } from "../colors"
import React from "react"

interface DividerProps {
  /**
   * The orientation of the divider.
   */
  vertical?: boolean
  /** Set the width of the divider */
  width?: string
  /** Make the divider grow */
  grow?: boolean
}

/**
 * A visual divider that renders as a line.
 */
export const Divider = ({ vertical, width, grow }: DividerProps) => {
  return (
    <div
      className={css`
        flex-grow: ${grow ? 1 : "initial"};
        height: ${vertical ? "100%" : "0"};
        width: ${grow ? "initial" : vertical ? "0" : width ? width : "100%"};
        border-bottom: ${vertical ? "none" : `1px solid ${Colors.greyT15}`};
        border-right: ${vertical ? `1px solid ${Colors.greyT15}` : "none"};
      `}
    />
  )
}
