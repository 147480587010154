import { envVars } from "common/config/constants"
import Cookies from "js-cookie"
import { ReactNode, useEffect } from "react"

interface PrivateRouteProps {
  children?: ReactNode
}

/**
 * User authentication check. If the user is NOT authenticated it should redirect the user to login in TES web page.
 * @param {ReactNode} children - All child components
 */
export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const accessToken = Cookies.get("Bearer")

  useEffect(() => {
    if (!accessToken) {
      window.open(envVars().TES_WEB_LOGIN_URL)
      return
    }
  }, [accessToken])

  return <>{children}</>
}
