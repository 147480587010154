import { Box, Stack } from "components/layout"
import { Text } from "components/typography"
import { useTranslation } from "react-i18next"
import { Button } from "./Button"
import { Modal } from "./Modal"

interface confirmProps {
  /** The title to show in the confirm dialog */
  title: string
  /** Add an ID to the element */
  id: string
  /** Callback function when the user closes the dialog */
  onClose: () => void
  /** Callback function when the user confirms  */
  onConfirm: () => void
}

/**
 * A modal that shows a confirm dialog.
 *
 * Used when asking a question that requires a simple yes or no answer
 */
export const Confirm = ({ title, id, onClose, onConfirm }: confirmProps) => {
  const { t } = useTranslation()

  const onConfirmButton = () => {
    onConfirm()
    onClose()
  }
  return (
    <Modal
      id={id}
      onClose={onClose}
      width="309px"
      header={
        <Box width="100%" justifyContent="center" alignItems="center">
          <Text variant="h1">{title}</Text>
        </Box>
      }
    >
      <Stack spacing="20px" fullWidth justifyContent="center" alignItems="center">
        <Button hotkey="n" autoFocus onClick={onClose} variant="secondary">
          {t("common.cancel", "Cancel")}
        </Button>
        <Button hotkey="y" onClick={onConfirmButton} variant="important">
          {t("common.confirm", "Confirm")}
        </Button>
      </Stack>
    </Modal>
  )
}
