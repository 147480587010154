import { css } from "@emotion/css"
import React, { ReactNode } from "react"
interface AutoFitProps {
  /**Elements to display in columns */
  children: ReactNode
  /**Minimum width of the child elements */
  minWidth?: string
  /** Spacing around the child elements */
  gap?: number
}

/**
 * Column component that adjusts its child components to fit rows with also wraps the components when min width is reached.
 *
 * It provides a typed, well-defined, interface for
 * working with a grid div
 *
 * Together with `<Stack>` `<Box>` this component allows us to
 * declaratively create pretty much any UI layout needed.
 */
export const AutoFit = ({ children, minWidth = "300px", gap }: AutoFitProps) => {
  return (
    <div
      className={css`
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(${minWidth}, 1fr));
        grid-gap: ${gap || "26px"};
      `}
    >
      {children}
    </div>
  )
}
