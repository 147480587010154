import { css } from "@emotion/css"
import { useRecording } from "common"
import {
  Box,
  Button,
  Icon,
  IconButton,
  MediaRangeSlider,
  PrettyTime,
  Stack,
  Text,
} from "components"
import { ChangeEvent, useEffect, useRef, useState } from "react"

/** This component renders the media player  */
export const AudioPlaybackBar = () => {
  const recording = useRecording()
  const audioRef = useRef<HTMLAudioElement>(null)
  const scrubberRef = useRef<HTMLDivElement>(null)
  const [progressPercent, setProgressPercent] = useState(0)

  const play = () => {
    recording.setIsPlaying(true)
  }
  const pause = () => {
    recording.setIsPlaying(false)
  }

  const skip5sek = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime
      audioRef.current.currentTime = currentTime - 5
    }
  }

  const setScrubberPosition = (percentage: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = percentage * audioRef.current.duration
    }
  }

  const setSliderValue = (event: ChangeEvent<HTMLInputElement>) => {
    const percentage = event.target.value
    setProgressPercent(Number(percentage))
  }

  const closeAndStop = () => {
    recording.setRecording("")
    recording.setIsPlaying(false)
  }

  useEffect(() => {
    if (recording.isPlaying) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }
  }, [recording.isPlaying])

  useEffect(() => {
    const audio = audioRef.current
    const setProgress = () => {
      audio && audio.duration && setProgressPercent((audio?.currentTime / audio?.duration) * 100)
    }
    audio?.addEventListener("timeupdate", setProgress)
    return () => {
      audio?.removeEventListener("timeupdate", setProgress)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      audioRef.current?.duration &&
        recording.timeStamp > 0 &&
        setScrubberPosition(recording.timeStamp / audioRef.current.duration)
    }, 100)
  }, [recording.timeStamp])

  return (
    <Box
      height="50px"
      alignItems="center"
      width="100%"
      background="azure"
      padding="27px 66px 27px 35px"
    >
      <audio ref={audioRef} src={recording.audioSrc} />
      <Stack fullHeight alignItems="center" fullWidth>
        <Button onClick={() => skip5sek()} variant="icon">
          <Icon name="replay_5" color="white" size="24px" />
        </Button>

        <Stack alignItems="center" fullWidth>
          <IconButton
            color="white"
            size="38px"
            name={recording.isPlaying ? "pause_circle_filled" : "play_circle_filled"}
            onClick={recording.isPlaying ? pause : play}
          />

          <div
            ref={scrubberRef}
            className={css`
              display: flex;
              width: 100%;
              height: 100%;
              position: relative;
              left: -8px;
            `}
            onClick={(e) =>
              setScrubberPosition(e.nativeEvent.offsetX / (scrubberRef.current?.clientWidth || 1))
            }
          >
            <MediaRangeSlider onChange={setSliderValue} value={progressPercent} />
          </div>
        </Stack>
        <Box padding="0px 126px 0px 26px">
          <Stack spacing="26px">
            <Text color="white">
              {<PrettyTime showUnits={false}>{audioRef.current?.currentTime}</PrettyTime>}
            </Text>
            {!Number.isNaN(audioRef.current?.duration) && (
              <Text opacity={0.5} color="white">
                {<PrettyTime showUnits={false}>{audioRef.current?.duration}</PrettyTime>}
              </Text>
            )}
          </Stack>
        </Box>
        <IconButton color="white" size="32px" name="close" onClick={() => closeAndStop()} />
      </Stack>
    </Box>
  )
}
