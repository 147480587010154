import { GetIncidents, GetCareRecipient, Incident, useNavigation } from "common"
import { Box, Card, Clickable, Header, LocalizedDate, ProgressBar, Stack, Text } from "components"
import { groupBy } from "lodash"
import { useTranslation } from "react-i18next"
import { RecipientHistoryIncident } from "./common"

/** Groups a list of incidents by date */
const groupIncidentsByDate = (incidents: Incident[]) => {
  const groupObject = groupBy(incidents, (incident) => {
    const createdTime = new Date(incident.createdTime)

    const date = new Date(
      createdTime.getFullYear(),
      createdTime.getMonth(),
      createdTime.getDate()
    ).toString()

    return date
  })
  return Object.entries(groupObject)
}
interface RecipientHistoryProps {
  /** The id of the recipient */
  recipientId: string
}
/**
 * A component that displays a list of incidents for a given care recipient. The incidents are grouped by date.
 */
export const RecipientHistory = ({ recipientId }: RecipientHistoryProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation()

  return recipientId ? (
    <GetCareRecipient careRecipientId={recipientId}>
      {({ data: careRecipient, loading }) =>
        careRecipient && !loading ? (
          <Card
            id="RecipientHistory"
            fullWidth
            minHeight="350px"
            focusHotkeyPattern="Ctrl+l"
            header={<Header icon="history">{t("recipient.history", "Recipient history")}</Header>}
          >
            <Box scroll="vertical" width="100%" position="relative">
              <GetIncidents careRecipientId={recipientId} refreshInterval={10000}>
                {({ data: incidents, loading }) =>
                  loading ? (
                    <ProgressBar />
                  ) : (
                    <Stack vertical fullWidth spacing="40px" padding="0 10px" height="0">
                      {groupIncidentsByDate(incidents || []).map(([date, incidents]) => (
                        <Stack key={date} vertical fullWidth spacing="10px">
                          <Box
                            position="sticky"
                            top="0"
                            background="white"
                            width="100%"
                            padding="0 0 4px 0"
                          >
                            <Text color="greyT5" variant="h5">
                              <LocalizedDate relative showTime={false}>
                                {date}
                              </LocalizedDate>
                            </Text>
                          </Box>
                          {incidents.map((incident) => (
                            <Clickable
                              fullWidth
                              key={incident.id}
                              onClick={
                                !navigation.isIncident(incident.id)
                                  ? () => navigation.goToIncident(recipientId, incident.id)
                                  : undefined
                              }
                            >
                              <RecipientHistoryIncident
                                active={navigation.isIncident(incident.id)}
                                incident={incident}
                              />
                            </Clickable>
                          ))}
                        </Stack>
                      ))}
                    </Stack>
                  )
                }
              </GetIncidents>
            </Box>
          </Card>
        ) : (
          <Card
            fullWidth
            header={<Header icon="history">{t("recipient.history", "History")}</Header>}
          >
            <ProgressBar />
          </Card>
        )
      }
    </GetCareRecipient>
  ) : (
    <Card
      minWidth="300px"
      header={<Header icon="history">{t("recipient.history", "History")}</Header>}
    >
      <ProgressBar />
    </Card>
  )
}
