import { Incident, PersonBadge } from "common"
import { IncidentLogItem, Stack, Timer, Text, Box } from "components"
import { AlarmType, AlarmPriority, IncidentState } from "features"

interface RecipientHistoryIncidentProps {
  /** Highlight this incident */
  active?: boolean
  /** The incident to show  */
  incident: Incident
}

/**
 * Show an incident with reason/type in the history
 */
export const RecipientHistoryIncident = ({ incident, active }: RecipientHistoryIncidentProps) => {
  return (
    <IncidentLogItem
      padding="10px"
      fullWidth
      isClosed={incident.state === "closed"}
      note={
        <Box padding="4px 0">
          <Text color={incident.state === "closed" ? "pureGreyDark" : "battleShipBlue"} bold>
            {incident.reasonDescription && incident.reasonDescription}
          </Text>
        </Box>
      }
      hasBorder={active}
      time={new Date(incident.createdTime)}
      heading={
        <Stack spacing="16px">
          <AlarmType
            isClosed={incident.state === "closed"}
            priority={incident.priority as AlarmPriority}
            alarmType={incident.typeDescription}
          />
          {incident.closedEvent?.type !== "closedWithoutAction" && (
            <IncidentState closedWithAction={true} state={incident.state} />
          )}
        </Stack>
      }
      subheading={
        incident.state !== "closed" && (
          <PersonBadge
            name={incident.responsiblePersonnel?.displayName}
            role={incident.latestEvent?.isPersonnelOperator ? "operator" : "staff"}
          />
        )
      }
      showTimer
      customTimer={
        <Timer
          border={active ? "greyT15" : "transparent"}
          from={incident.createdTime}
          to={incident.closedEvent?.createdDate}
        />
      }
    />
  )
}
