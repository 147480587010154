import { SLAResponse } from "common"
import { Box, IncidentLogItem, SLATimer, Stack, Text } from "components"
import { AlarmPriority, AlarmType } from "../AlarmType"

interface IncidentProps {
  /** The time when the incident was created */
  date: Date
  /** The department name associated with this incident */
  department?: string
  /** The recipient name associated with this incident */
  recipient?: string
  /** Type of the incident  */
  incidentType: string
  /**Show timer */
  showTimer?: boolean
  /** Remove background */
  noBackground?: boolean
  /** Toggles the minimized mode */
  minimized?: boolean
  /** Make the event slightly smaller */
  small?: boolean
  /** The reason description of the incident */
  reasonDescription?: string | undefined
  /** SLA object */
  sla?: SLAResponse | undefined
  /** The priority of the Alarm */
  priority?: AlarmPriority
  /** Id used for targeting elements for UI tests */
  id?: string
}

/**
 * Display an Incident like an event, with time and an icon etc
 *
 * @todo Map alarm codes to icons
 */
export const Incident = ({
  date,
  department,
  recipient,
  incidentType,
  showTimer = true,
  noBackground,
  minimized,
  small,
  sla,
  priority,
  id,
}: IncidentProps) => (
  <Box>
    {minimized ? (
      sla && <SLATimer sla={sla} />
    ) : (
      <IncidentLogItem
        background={noBackground ? undefined : "transparent"}
        subheading={
          <Text variant="h5" color="greyT5">
            {department}
          </Text>
        }
        heading={
          <Stack vertical spacing="5px">
            <Text id={id} color="battleShipBlue" bold>
              {recipient}
            </Text>
          </Stack>
        }
        time={date}
        kicker={
          priority !== undefined && <AlarmType alarmType={incidentType} priority={priority} />
        }
        showTimer={showTimer}
        small={small}
        sla={sla}
      />
    )}
  </Box>
)
