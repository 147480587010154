import { ColorName } from "components/colors"
import { useEffect, useMemo, useState } from "react"
import { Time } from "./Time"

type TimerProps = {
  /** A date or ISO 8601 string that represents the start time */
  from: string | Date
  /** A date or ISO 8601 string that represents the end time */
  to?: string | Date
  /** Show a colored border around the component */
  border?: ColorName
}

/**
 * A timer component that renders the time since the `from` date until the `to` date.
 * If the `to` date is not provided, the timer will count up indefinitely.
 */
export const Timer = ({ from, to, border }: TimerProps) => {
  const fromEpoch = useMemo(() => (!!from ? new Date(from) : new Date()), [from])
  const toEpoch = useMemo(() => (!!to ? new Date(to) : new Date()), [to])
  const [difference, setDifference] = useState((toEpoch.getTime() - fromEpoch.getTime()) / 1000)

  useEffect(() => {
    if (!to) {
      const interval = setInterval(() => {
        setDifference((Date.now() - fromEpoch.getTime()) / 1000)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [fromEpoch, to, difference])

  return <Time disabled={Boolean(to)} border={border} seconds={difference} />
}
