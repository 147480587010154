import { useIncident } from "common"
import { Stack, Text } from "components"
import { useTranslation } from "react-i18next"

/**
 * This component is shown while the incident is being handled by a care staff.
 */

export const HandleIncidentCareStaff = ({ incidentId }: { incidentId: string }) => {
  const { t } = useTranslation()
  const { data: incident } = useIncident(incidentId)
  return (
    <Stack vertical spacing="8px">
      {incident?.state === "queuedApp" ? (
        <Text id="CareStaffIsHandling" variant="h2">
          {t("incident.waitingForStaffToAccept", "Waiting for staff to accept")}
        </Text>
      ) : (
        <Text id="CareStaffIsHandling" variant="h2">
          {t("incident.staffIsHandling", "Care staff is handling the incident.")}
        </Text>
      )}
    </Stack>
  )
}
