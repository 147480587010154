import { IncidentEvent } from "common"
import { Text } from "components"
import { useTranslation } from "react-i18next"

export const EventType = ({ type }: { type: IncidentEvent }) => {
  const { t } = useTranslation()

  const eventTypeToTitle: Record<IncidentEvent, string> = {
    paged: t("event.paged", "Transferred to staff"),
    takenFromQueue: t("event.takenByOperator", "Taken by operator"),
    queued: t("event.queued", "Queued"),
    callConnected: t("event.callConnected", "Call answered"),
    callEnded: t("event.callDisconnected", "Call closed"),
    accepted: t("event.accepted", "Staff accepted"),
    presence: t("event.presence", "Staff present"),
    presenceDone: t("event.presenceDone", "Staff finished"),
    done: t("event.done", "Closed"),
    alarmReceived: t("event.noteAdded", "Created"),
    rejected: t("event.rejected", "Rejected"),
    timedOut: t("event.timedOut", "Timed out"),
    acceptSent: t("event.acceptSent", "Accept sent"),
    acceptReceived: t("event.acceptReceived", "Accept received"),
    timeOutWorkOrder: t("event.timeOutWorkOrder", "Timed out work order"),
    sentToArc: t("event.sentToArc", "Sent to ARC"),
    rejectedByArc: t("event.rejectedByArc", "Rejected by ARC"),
    acceptedByArc: t("event.acceptedByArc", "Accepted by ARC"),
    closedWithoutAction: t("event.closedWithoutAction", "Closed without action"),
    noteAdded: "",
    reasonSet: t("event.reasonSet", "Reason set"),
    pagedByPhone: t("event.pagedByPhone", "Paged by phone"),
  }

  return <Text bold>{eventTypeToTitle[type]}</Text>
}
