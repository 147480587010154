import { useEvents, useIncident } from "common"
import { ActionText, Box, Divider, ProgressBar, Stack } from "components"
import { HandlingScreen } from "features"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface IncidentInfoProps {
  /** The incident to display */
  incidentId: string
  /** The care recipient associated with the incident */
  recipientId: string
}

type screens = "handleReason" | "chooseAction" | "handleIncident" | "incidentClosed"

/**
 * This component renders the different actions that can be taken on the incident
 */
export const HandleIncident = ({ incidentId, recipientId }: IncidentInfoProps) => {
  const { t } = useTranslation()
  const { data: incident, loading: incidentLoading } = useIncident(incidentId, 10000)
  const { data: events } = useEvents(incidentId, 10000)
  //TODO: add support for multiple close events (e.g. done and timeout)
  const hasReason = (events?.filter((e) => e.type === "reasonSet") || []).length > 0
  const hasTransfer = (events?.filter((e) => e.type === "paged") || []).length > 0
  const hasClosed =
    (events?.filter((e) => e.type === "closedWithoutAction") || []).length > 0 ||
    incident?.state === "closed"
  const [currentHandlingScreen, setCurrentHandlingScreen] = useState<screens>("handleReason")

  useEffect(() => {
    if (hasClosed) setCurrentHandlingScreen("incidentClosed")
    else if (hasTransfer) setCurrentHandlingScreen("handleIncident")
    else if (hasReason) setCurrentHandlingScreen("chooseAction")
    else setCurrentHandlingScreen("handleReason")
  }, [hasReason, hasTransfer, hasClosed])

  return (
    <Box
      borderColor="greyT15"
      background="aliceBlue"
      padding="30px 0 40px 0"
      borderWidth="1px 0 0 0"
      vertical
      width="100%"
    >
      <Stack spacing="40px" vertical fullWidth alignItems="center" justifyContent="center">
        {incident ? (
          <Box width="70%">
            <Stack fullWidth alignItems="center" justifyContent="space-between" spacing="40px">
              <ActionText
                disabled={incident.state === "closed" || incident.state === "queued"}
                active={currentHandlingScreen === "handleReason"}
                onClick={() => setCurrentHandlingScreen("handleReason")}
              >
                {t("incident.determineReason", "Determine reason")}
              </ActionText>
              <Divider grow />
              <ActionText
                disabled={
                  incident.state === "closed" ||
                  incident.state === "queued" ||
                  !hasReason ||
                  hasTransfer
                }
                active={currentHandlingScreen === "chooseAction"}
                onClick={() => setCurrentHandlingScreen("chooseAction")}
              >
                {t("incident.chooseAction", "Choose action")}
              </ActionText>
              <Divider grow />
              <ActionText
                disabled={
                  incident.state === "handledByOperator" ||
                  incident.state === "closed" ||
                  incident.state === "queued"
                }
                active={currentHandlingScreen === "handleIncident"}
                onClick={() => setCurrentHandlingScreen("handleIncident")}
              >
                {t("incident.handleIncident", "Handle Incident")}
              </ActionText>

              <Divider grow />
              <ActionText
                disabled={incident.state !== "closed"}
                active={currentHandlingScreen === "incidentClosed"}
              >
                {t("incident.incidentClosed", "Incident closed")}
              </ActionText>
            </Stack>
          </Box>
        ) : (
          <ProgressBar />
        )}

        {incident && (
          <HandlingScreen
            recipientId={recipientId}
            incidentId={incident.id}
            disabled={incidentLoading}
            handlingScreen={currentHandlingScreen}
          />
        )}
      </Stack>
    </Box>
  )
}
