import { Stack } from "../layout"
import React, { ReactNode } from "react"
import { Icon } from "../ui/Icon"
import { Text } from "./Text"
import { IconName } from "../icons"

interface HeaderProps {
  /** Name of the icon to be displayed */
  icon?: IconName
  /** Text to be displayed in the kicker above the main headline */
  kicker?: string | ReactNode
  /** Text to be displayed in the subheading below the main headline */
  subheading?: string | ReactNode
  /** The main headline text to be displayed in the header */
  children: ReactNode
  /** Add an ID to the main text element */
  id?: string
  /** Important mode sets coral color */
  important?: boolean
}

/**
 * A versatile header with the following optional features:
 *
 * * `Kicker` - A small text shown above the main headline
 * * `Subheading` - A text shown below the main headline
 * * `Icon` - An icon to display to the left of the header
 *
 */
export const Header = ({ icon, kicker, subheading, children, important, id }: HeaderProps) => {
  return (
    <Stack alignItems="center" fullWidth spacing="13px">
      {icon && <Icon color={important ? "coral" : "greyT15"} size="28px" name={icon} />}
      <Stack fullWidth vertical alignItems="start" spacing="4px">
        {typeof kicker === "string" ? (
          <Text color={important ? "coral" : "battleShipBlue"} variant="h4">
            {kicker}
          </Text>
        ) : (
          kicker
        )}
        {typeof children === "string" ? (
          <Text id={id} color={important ? "coral" : "battleShipBlue"} variant="h1">
            {children}
          </Text>
        ) : (
          children
        )}
        {subheading && <Text color={important ? "coral" : "battleShipBlue"}>{subheading}</Text>}
      </Stack>
    </Stack>
  )
}
