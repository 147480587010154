import { IncidentLogEvent, useRecording } from "common"
import { Box, Text, LogItem } from "components"
import { EventType } from "features"

interface TimeLineEventProps {
  /** The event to display */
  event: IncidentLogEvent

  /** Is happening within a call */
  recordingStartTimestamp?: boolean | string
  /** Audio source to be played  */
  audioSrc?: string

  end?: boolean

  isIncidentList?: boolean
}

/**
 * A component that renders a timeline event
 */
export const LogItemEvent = ({
  event,
  end,
  recordingStartTimestamp,
  audioSrc,
  isIncidentList,
}: TimeLineEventProps) => {
  const recording = useRecording()

  const audioControl = (isPlaying: boolean, dateStamp?: string) => {
    const skipTo =
      typeof recordingStartTimestamp === "string" && dateStamp
        ? (new Date(dateStamp).getTime() - new Date(recordingStartTimestamp).getTime()) / 1000
        : 0
    recording.audioSrc !== audioSrc && audioSrc && recording.setRecording(audioSrc)
    skipTo !== 0 && recording.setTimeStamp(skipTo)
    recording.setIsPlaying(isPlaying)
  }

  return (
    <LogItem
      isIncidentList={isIncidentList}
      end={end}
      eventType={event.type}
      setRecording={audioSrc ? audioControl : undefined}
      hasRecording={!!audioSrc}
      createdBy={event.createdBy?.displayName}
      timeStamp={event.createdDate}
      userRole={event.isPersonnelOperator ? "operator" : "staff"}
      title={
        event.type === "reasonSet" ? (
          event.reason?.description
        ) : (
          <EventType type={event.type} />
        )
      }
      sla={isIncidentList ? undefined : event.sla}
    >
      {event.note && (
        <Box
          maxWidth="226px"
          background={event.isPersonnelOperator ? "lightBeige" : "aliceBlue2"}
          padding="12px 16px"
          radius="4px"
        >
          <Text
            wordBreak
            whiteSpace="pre-wrap"
            color={event.isPersonnelOperator ? "brown" : "battleShipBlue"}
          >
            {event.note}
          </Text>
        </Box>
      )}
    </LogItem>
  )
}
