import { HubConnectionState } from "@microsoft/signalr"
import { QueueIncident } from "common"
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react"

interface DebugInfoContextProps {
  connectionState: HubConnectionState
  setConnectionState: Dispatch<SetStateAction<HubConnectionState>>
  connectionId: string
  setConnectionId: Dispatch<SetStateAction<string>>
  incidentList: QueueIncident[]
  setIncidentList: Dispatch<SetStateAction<QueueIncident[]>>
}

export const DebugContext = createContext<DebugInfoContextProps>({
  connectionState: HubConnectionState.Connecting,
  setConnectionState: () => {},
  connectionId: "",
  setConnectionId: () => {},
  incidentList: [],
  setIncidentList: () => {},
})

export const useDebug = () => useContext(DebugContext)

interface DebugProviderProps {
  children: ReactNode
}
export const DebugProvider = ({ children }: DebugProviderProps) => {
  const [connectionState, setConnectionState] = useState<HubConnectionState>(
    HubConnectionState.Connecting
  )
  const [incidentList, setIncidentList] = useState<QueueIncident[]>([])
  const [connectionId, setConnectionId] = useState("")

  return (
    <DebugContext.Provider
      value={{
        connectionState,
        setConnectionState,
        connectionId,
        setConnectionId,
        incidentList,
        setIncidentList,
      }}
    >
      {children}
    </DebugContext.Provider>
  )
}
