import { SLAResponse } from "common"
import { SLATimer } from "components"
import { ColorName } from "components/colors"
import { ReactNode } from "react"
import { LocalizedDate } from "../data/LocalizedDate"
import { Box } from "../layout/Box"
import { Stack } from "../layout/Stack"
import { Text } from "../typography/Text"
import { Timer } from "./time/Timer"

interface IncidentLogItemProps {
  /** The text above the main heading */
  kicker?: ReactNode | string
  /** The main heading of this event */
  heading?: ReactNode | string
  /** The text below the main heading */
  subheading?: ReactNode
  /** The time this event happened */
  time: Date
  /** The time this event ended */
  endTime?: Date
  /** If the start time differs from the event time this can be used to calculate the total time */
  startTime?: Date

  /** Show a timer to the right of the title */
  showTimer?: boolean
  /** Replace the default timer */
  customTimer?: ReactNode
  /** Displays a background */
  background?: ColorName
  /** Make the event slightly smaller */
  small?: boolean
  /** Note to display for the event */
  note?: ReactNode | string
  /** Full width*/
  fullWidth?: boolean
  /** Adds padding around the event item */
  padding?: string
  /** decides if the incident is closed */
  isClosed?: boolean
  /** SLA object */
  sla?: SLAResponse | undefined
  hasBorder?: boolean
}

/**
 * Can display any data that has a timestamp associated with it
 * such as an event or an alarm (in the queue)
 */
export const IncidentLogItem = ({
  heading,
  subheading,
  kicker,
  time,
  endTime,
  showTimer,
  customTimer,
  background,
  startTime,
  small,
  note,
  fullWidth,
  padding,
  isClosed,
  sla,
  hasBorder,
}: IncidentLogItemProps) => (
  <Box
    background={background || "transparent"}
    padding={padding ? padding : small ? "0px" : "0 10px"}
    justifyContent="space-between"
    width={fullWidth ? "100%" : small ? "170px" : "270px"}
    minHeight="50px"
    radius="4px"
    hideOverflow
    borderWidth={hasBorder ? "1px" : "none"}
    borderColor={hasBorder ? "azure" : "transparent"}
    borderStyle={hasBorder ? "dashed" : "solid"}
    alignItems="start"
  >
    <Stack padding="0 17px 0 0" spacing="21px" fullWidth>
      <Text color={isClosed ? "pureGreyDark" : "battleShipBlue"} variant="h5">
        <LocalizedDate showDate={false}>{time}</LocalizedDate>
      </Text>

      <Stack vertical spacing="4px">
        {kicker && (
          <Text variant="h5" color={isClosed ? "pureGreyDark" : "greyT5"}>
            {kicker}
          </Text>
        )}
        {typeof heading === "string" ? (
          <>
            <Text color={isClosed ? "pureGreyDark" : "battleShipBlue"} bold>
              {heading}
            </Text>
          </>
        ) : (
          heading
        )}
        {note && (
          <>
            {typeof note === "string" ? (
              <Box
                maxWidth="226px"
                background="white"
                padding="14px"
                borderColor="greyT20"
                borderWidth="1px"
                radius="4px"
              >
                <Text color="black" light>
                  {note}
                </Text>
              </Box>
            ) : (
              note
            )}
          </>
        )}
        {subheading &&
          (typeof subheading === "string" ? (
            <Text color={isClosed ? "pureGreyDark" : "battleShipBlue"} light>
              {subheading}
            </Text>
          ) : (
            subheading
          ))}
      </Stack>
    </Stack>
    <Box alignItems="start" height="100%">
      {sla ? (
        <SLATimer sla={sla} />
      ) : (
        showTimer && (customTimer || <Timer from={startTime || time} to={endTime} />)
      )}
    </Box>
  </Box>
)
