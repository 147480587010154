import { GetCareRecipient } from "common"
import { Box, Card, Header, Information, Map, ProgressBar, Stack } from "components"
import { useTranslation } from "react-i18next"

interface MapCardProps {
  /** The id of the recipient */
  recipientId: string
}

/**
 * This component is used to display a map
 */
export const MapCard = ({ recipientId }: MapCardProps) => {
  const { t } = useTranslation()
  return (
    <GetCareRecipient careRecipientId={recipientId}>
      {({ data: careRecipient, loading }) =>
        careRecipient && !loading ? (
          careRecipient.details?.place?.city && (
            <Card
              fullWidth
              header={
                <Header
                  id="mapHeader"
                  icon="home"
                  subheading={`${careRecipient.details?.place?.postalCode} ${careRecipient.details?.place?.city}`}
                >
                  {careRecipient.details?.place?.streetAddress}
                </Header>
              }
            >
              <Box background="white" width="100%">
                <Stack spacing="48px">
                  <Stack spacing="27px" vertical>
                    <Information
                      icon="vpn_key"
                      heading={t("recipient.doorCode", "Door code")}
                      maxWidth="300px"
                    >
                      {careRecipient.details?.place?.doorCode}
                    </Information>
                    <Information
                      icon="navigation"
                      heading={t("recipient.directions", "Directions")}
                      maxWidth="300px"
                    >
                      {careRecipient.details?.place?.routeDescription}
                    </Information>
                  </Stack>
                  <Map
                    coordinates={[careRecipient.details?.place?.longitude, careRecipient.details?.place?.latitude]}
                    width={600}
                    height={197}
                  />
                </Stack>
              </Box>
            </Card>
          )
        ) : (
          <Card header={t("general.map", "Map")} fullWidth>
            <ProgressBar />
          </Card>
        )
      }
    </GetCareRecipient>
  )
}
