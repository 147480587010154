import { css, cx } from "@emotion/css"
import { ReactNode } from "react"
import { ColorName, Colors } from "../../colors"

interface TableRowProps {
  color?: ColorName
  children: ReactNode
}

const trStyle = css`
  height: 42px;

  td {
    border-bottom: 1px solid ${Colors.greyT20};
    padding: 6px 10px;
  }
  td:first-child,
  td:last-child {
    border-bottom: none !important;
  }
  td.show-on-hover > * {
    opacity: 0;
  }
  &:hover {
    z-index: 1;
    filter: brightness(98.5%);
  }
  &:hover td.show-on-hover > * {
    opacity: 1;
  }
`

export const TableRow = ({ color = "white", children }: TableRowProps) => (
  <tr
    className={cx(
      trStyle,
      color &&
        css`
          background-color: ${Colors[color]};
        `
    )}
  >
    <td />
    {children}
    <td />
  </tr>
)
