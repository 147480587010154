import { RefObject, useEffect, useRef, useState } from "react"

/**
 * Determine if the provided element
 * is scrolling or not
 */
export const useIsScrolling = (refToWatch: RefObject<HTMLDivElement>) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const scrollTimer = useRef<NodeJS.Timeout | null>(null)

  const scrollListener = () => {
    // Scroll Started
    if (scrollTimer.current === null) {
      setIsScrolling(true)
    }

    // We are scrolling
    if (scrollTimer.current !== null) {
      clearTimeout(scrollTimer.current)
    }

    // Scroll ended
    scrollTimer.current = setTimeout(() => {
      setIsScrolling(false)
      scrollTimer.current = null
    }, 100)
  }

  const handleScrollListener = () => {
    const currentRef = refToWatch.current
    currentRef?.addEventListener("scroll", scrollListener)
    return () => currentRef?.removeEventListener("scroll", scrollListener)
  }

  useEffect(handleScrollListener, [refToWatch])

  return isScrolling
}
