import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr"
import { envVars, SLAResponse, useOperator } from "common"
import { useDebug } from "features/debug"
import Cookies from "js-cookie"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"

interface CareRecipient {
  id: string
  name: string
}

export interface QueueIncident {
  id: string
  createdTime: string
  careRecipients: CareRecipient[]
  departmentName: string
  priority: number
  typeDescription: string
  ongoingSla: SLAResponse
}

interface SignalRIncidentData {
  averageResponseTime: number
  queueIncidents: QueueIncident[]
}

interface Queue {
  incidentList: QueueIncident[]
  averageResponseTime: number
}

const QueueContext = createContext<Queue>({ incidentList: [], averageResponseTime: 0 })

export const useQueue = () => useContext(QueueContext)
/**
 * Set up a SignalR connection that listens to the
 * incident queue
 */
export const QueueProvider = ({ children }: { children: ReactNode }) => {
  const [incidentList, setIncidentList] = useState<QueueIncident[]>([])
  const [averageResponseTime, setAverageResponseTime] = useState<number>(0)
  const [connection, setConnection] = useState<HubConnection>()
  const accessToken = Cookies.get("Bearer")
  const operator = useOperator()
  const debug = useDebug()
  const retryTimeout = 600 // After 10 minutes
  const retryInterval = 10 // Every 10 seconds
  const reconnectAttempts = Array.from(
    { length: retryTimeout / retryInterval },
    (_, i) => 1000 * retryInterval * i
  )

  useEffect(() => {
    if (!operator.personnelId) {
      return
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${envVars().SR_BASE_URL}?clientId=${operator.personnelId}`, {
        accessTokenFactory: () => `${accessToken}`,
      })
      .withAutomaticReconnect(reconnectAttempts)
      .build()

    setConnection(newConnection)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, operator.personnelId])

  useEffect(() => {
    if (!connection) return

    connection.start().then(() => {
      const updateIncidentList = (message: string) => {
        if (message === null) return
        const parsedMessage: SignalRIncidentData = JSON.parse(message)
        const data = {
          incidentList: parsedMessage.queueIncidents,
          incidentsUpdatedAt: new Date().toISOString(),
          averageResponseTime: parsedMessage.averageResponseTime,
        }

        setIncidentList(data.incidentList)
        setAverageResponseTime(data.averageResponseTime)
      }
      const updateConnectionInfo = () => {
        debug.setConnectionState(connection.state)
        debug.setConnectionId(connection.connectionId || "")
      }

      connection.on("ReceiveIncidents", updateIncidentList)
      connection.onclose(updateConnectionInfo)
      connection.onreconnecting(updateConnectionInfo)
      connection.onreconnected(updateConnectionInfo)
      updateConnectionInfo()
    })
    return () => {
      connection.stop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])

  return (
    <QueueContext.Provider
      value={{
        incidentList,
        averageResponseTime,
      }}
    >
      {children}
    </QueueContext.Provider>
  )
}
