import { css } from "@emotion/css"
import {
  GetEvents,
  GetCareRecipient,
  Incident,
  IncidentEvent,
  IncidentLogEvent,
  PersonBadge,
  useEvents,
  useGetRecording,
} from "common"
import {
  Box,
  Card,
  Colors,
  Divider,
  Header,
  LocalizedDate,
  ProgressBar,
  SLATimer,
  Stack,
  Text,
  LogItem,
} from "components"
import { AddNote, AlarmPriority, AlarmType, IncidentState, LogItemEvent } from "features"
import { groupBy } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

interface TimeLineProps {
  /** The incident that will be shown in the timeline  */
  incident?: Incident | null
}
/**
 * A list of events we do not show in the timeline
 */
const eventsToHide: IncidentEvent[] = [
  "queued",
  "sentToArc",
  "acceptedByArc",
  "alarmReceived",
  "takenFromQueue",
]

/** Groups a list of events, after closed event sorted by date */
const groupEventsByDate = (events: IncidentLogEvent[]) => {
  const groupObject = groupBy(events, (event) => {
    const receivedTime = new Date(event.createdDate)
    const date = new Date(
      receivedTime.getFullYear(),
      receivedTime.getMonth(),
      receivedTime.getDate()
    ).toString()
    return date
  })
  return Object.entries(groupObject)
}

export const TimeLine = ({ incident }: TimeLineProps) => {
  const { t } = useTranslation()
  const { data: audioSrc } = useGetRecording(incident?.id)
  const { data: events } = useEvents(incident?.id)
  const eventsAfterClosed = (event: IncidentLogEvent) =>
    incident?.closedEvent?.createdDate ? new Date(event.createdDate) > new Date(incident?.closedEvent?.createdDate) : false
  const eventsBeforeClosed = (event: IncidentLogEvent) =>
    incident?.closedEvent?.createdDate ? new Date(event.createdDate) <= new Date(incident?.closedEvent?.createdDate) : true

  return (
    <Card
      fullWidth
      header={
        incident ? (
          <Header id="timeLineHeader" icon="info">
            {incident.reasonDescription || incident.typeDescription}
          </Header>
        ) : (
          <Header>{t("TimeLine.header", "TimeLine")}</Header>
        )
      }
    >
      <Stack alignItems="center" vertical spacing="24px" fullWidth>
        {incident && incident.state !== "closed" && (
          <Box
            padding="25px"
            height="72px"
            background="aliceBlue"
            width="94%"
            borderWidth="1px"
            borderColor="greyT15"
            radius="6px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack vertical spacing="6px">
              {incident && (
                <IncidentState
                  id="incidentState"
                  closedWithAction={incident.closedEvent?.type !== "closedWithoutAction"}
                  state={incident.state}
                />
              )}
              {/* TODO: Get role from personnel when backend has  */}
              <PersonBadge role="operator" name={incident.responsiblePersonnel?.displayName} />
            </Stack>
            {incident.ongoingSla && <SLATimer id="CurrentSLA" sla={incident.ongoingSla} />}
          </Box>
        )}
        {events &&
          groupEventsByDate(events.filter(eventsAfterClosed)).map(([date, events]) => (
            <Box vertical padding="0 20px" width="100%">
              <Stack key={date} vertical fullWidth spacing="10px">
                <Box padding="20px 0 20px 90px" position="sticky" top="0" width="100%">
                  <Text color="greyT5" variant="h5">
                    <LocalizedDate relative showTime={false}>
                      {date}
                    </LocalizedDate>
                  </Text>
                </Box>
                {events.map((event) => (
                  <LogItemEvent event={event} audioSrc={audioSrc} />
                ))}
              </Stack>
            </Box>
          ))}
        <Box padding=" 0 20px 20px 20px" width="100%" position="relative">
          <div
            className={css`
              &::after {
                content: "";
                position: absolute;
                top: 80px;
                bottom: 60px;
                left: 54px;
                border-left: 1px solid ${Colors.greyT20};
                z-index: 0;
              }
            `}
          ></div>

          <Stack fullWidth vertical spacing="32px">
            {incident ? (
              <Stack spacing="20px" fullWidth vertical>
                <GetEvents incidentId={incident?.id} refreshInterval={10000}>
                  {({ data: events }) => {
                    return (
                      events &&
                      groupEventsByDate(
                        events
                          .filter((event) => !eventsToHide.includes(event.type))
                          .filter(eventsBeforeClosed)
                      ).map(([date, events], index) => (
                        <React.Fragment key={date + index}>
                          <Box padding="20px 0 20px 90px" position="sticky" top="0" width="100%">
                            <Text color="greyT5" variant="h5">
                              <LocalizedDate relative showTime={false}>
                                {date}
                              </LocalizedDate>
                            </Text>
                          </Box>
                          {events.map((event, index) => {
                            return (
                              <LogItemEvent
                                end={index === 0}
                                audioSrc={audioSrc}
                                key={`${event.id}${index}`}
                                event={event}
                              />
                            )
                          })}
                        </React.Fragment>
                      ))
                    )
                  }}
                </GetEvents>
                <GetCareRecipient careRecipientId={incident?.careRecipients[0].id}>
                  {({ data: careRecipient }) =>
                    careRecipient && (
                      <LogItem
                        start
                        title={
                          // TODO: Check the typing with Tob and fix this
                          <AlarmType
                            priority={incident.priority as AlarmPriority}
                            alarmType={incident.typeDescription}
                          />
                        }
                        createdBy={`${careRecipient.name}`}
                        timeStamp={incident.createdTime}
                      />
                    )
                  }
                </GetCareRecipient>
              </Stack>
            ) : (
              <ProgressBar />
            )}
          </Stack>
        </Box>
        <Stack spacing="36px" vertical fullWidth>
          <Divider />
          {incident && <AddNote incidentId={incident?.id} />}
        </Stack>
      </Stack>
    </Card>
  )
}
