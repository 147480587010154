/**
 * All the colors we use in the app.
 */
export const Colors = {
  green: "#3AB15B",
  greenLight: "#40A900",
  coral: "#FF5C72",
  azure: "#0099FE",
  orange: "#F2994A",
  purple: "#C3389C",
  lightPurple: "#A383FC",
  mustard: "#B6A400",
  lightBeige: "#FFF4CD",
  brown: "#895E1B",
  twilightRed: "#FFF0FC",
  twilightRedStrong: "#FEE4F4",
  twilightRedWeak: "#FFF6FD",
  battleShipBlue: "#4B6278",
  grey: "#697785",
  greyT5: "#8A9BAD",
  greyT10: "#BAC6D5",
  greyT15: "#CAD3DF",
  greyT20: "#DAE0E9",
  pureGreyLight: "#F3F3F3",
  pureGreyLightest: "#FCFCFC",
  pureGreyDark: "#A6A6A6",
  aliceBlue: "#FAFCFF",
  aliceBlue2: "#EFF5FF",
  aliceBlue3: "#E6EFFF",
  black: "#000",
  white: "#ffffff",
  transparent: "transparent",
  blackTransparent: "rgba(0,0,0,0.05)",
}

/**
 * The name of a color we use.
 * Used to fetch a color.
 *
 * @example Colors[ColorName]
 */
export type ColorName = keyof typeof Colors
