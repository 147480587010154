import { css } from "@emotion/css"
import { ReactNode } from "react"
import { Colors } from "../colors"

interface ClickableProps {
  /** The component you want to be clickable */
  children: ReactNode
  /** Callback function to execute when the component is clicked. */
  onClick?: () => void
  /**
   * Disable the clickability.
   *
   * This also removes the tab focusability
   * */
  disabled?: boolean
  /** Set the tab index */
  tabIndex?: number
  /** Make the click container expand to 100% width */
  fullWidth?: boolean
  /** Add an ID to the element */
  id?: string
  /** Make the click container expand to 100% height */
  fullHeight?: boolean
}

/**
 * Wrap any component in order to make it clickable.
 *
 * This provides the correct styling, aria role and makes the component focusable by tabbing
 */
export const Clickable = ({
  children,
  onClick,
  disabled,
  fullWidth,
  tabIndex = 0,
  id,
  fullHeight,
}: ClickableProps) => (
  <div
    id={id}
    onClick={disabled ? undefined : onClick}
    onKeyDownCapture={(event) => {
      if (onClick && (event.key === " " || event.key === "Enter")) {
        event.preventDefault()
        onClick()
      }
    }}
    role="button"
    aria-disabled={disabled || !onClick}
    tabIndex={disabled || !onClick ? -1 : tabIndex}
    className={css`
      display: flex;
      width: ${fullWidth ? "100%" : undefined};
      height: ${fullHeight ? "100%" : "auto"};
      min-height: 0;
      user-select: none;
      :focus:not(:focus-visible) {
        outline: none;
      }
      :focus-visible {
        outline: none;
      }
      & > * {
        outline: none !important;
      }
      ${onClick &&
      `cursor: pointer;
      :focus {
        outline: 0.5px solid ${Colors.azure};
        outline-offset: 4px;
      }
      `}
    `}
  >
    {children}
  </div>
)
