import { css } from "@emotion/css"
import { Hotkey, Stack } from "components"
import { ReactNode } from "react"
import { ColorName, Colors } from "../colors"
import { Text } from "../typography/Text"

interface ButtonProps {
  /** The button text */
  children?: ReactNode
  /** Set an ID on the element */
  id?: string
  /** Display the button in different ways */
  variant: "text" | "important" | "primary" | "secondary" | "icon"
  /** The button click callback */
  onClick?: () => void
  /** Disable the button */
  disabled?: boolean
  /** Set the tab index of the element */
  tabIndex?: number
  /** Make the button take up full width */
  fullWidth?: boolean
  /** Automatically focus the button on mount  */
  autoFocus?: boolean
  /** Pattern for hotkey */
  hotkey?: string
  /** Removes outline on focus*/
  noOutline?: boolean
  /** Type of button */
  type?: "button" | "submit"
}

const variantColor: Record<ButtonProps["variant"], ColorName> = {
  important: "coral",
  primary: "azure",
  secondary: "transparent",
  text: "transparent",
  icon: "transparent",
}

const borderColor: Record<ButtonProps["variant"], ColorName> = {
  important: "coral",
  primary: "azure",
  secondary: "azure",
  text: "transparent",
  icon: "azure",
}

const textColor: Record<ButtonProps["variant"], ColorName> = {
  important: "white",
  primary: "white",
  secondary: "azure",
  text: "azure",
  icon: "azure",
}

/**
 * A basic button.
 *
 */
export const Button = ({
  children,
  id,
  variant,
  disabled,
  onClick,
  tabIndex,
  fullWidth,
  autoFocus,
  hotkey,
  noOutline,
  type = "button",
}: ButtonProps) => {
  return (
    <button
      type={type}
      autoFocus={autoFocus}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      id={id}
      className={css`
        display: flex;
        align-items: center;
        background-color: ${Colors[variantColor[variant]]};
        border-color: ${Colors[borderColor[variant]]};
        color: ${Colors[textColor[variant]]};
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        flex-shrink: 0;
        max-height: 31px;
        align-items: center;
        justify-content: center;
        width: ${fullWidth ? "100%" : undefined};
        padding: ${variant !== "icon" ? "7px 9px" : "4px"};
        cursor: pointer;
        &:disabled {
          border-color: ${Colors.transparent};
          color: ${Colors.black};
          cursor: not-allowed;
          opacity: 0.5;
        }
        &:focus {
          outline: ${noOutline && "none"};
          filter: brightness(120%);
        }
      `}
    >
      <Stack spacing="8px" alignItems="center">
        {typeof children === "string" ? (
          <Text bold color={textColor[variant]}>
            {children}
          </Text>
        ) : (
          children
        )}
        {hotkey && (
          <Hotkey
            id={id ? `${id}-hotkey` : undefined}
            disabled={disabled}
            color={textColor[variant]}
            pattern={hotkey}
            callback={onClick}
          />
        )}
      </Stack>
    </button>
  )
}
