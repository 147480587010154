import {
  CallProvider,
  envVars,
  HotkeysProvider,
  LoadingProvider,
  OperatorProvider,
  PrivateRoute,
  QueueProvider,
  RecordingProvider,
  routes,
  SnackbarProvider,
} from "common"
import { Box, ErrorBoundary, Heading, Stack } from "components"
import { CallBar, DebugInfo, DebugProvider, IncidentPage, SideBar } from "features"
import { HomePage } from "features/home"
import { useTranslation } from "react-i18next"
import { Route, Routes } from "react-router"
import { HashRouter } from "react-router-dom"

export const App = () => {
  return (
    <HashRouter>
      <DebugProvider>
        <SnackbarProvider>
          <PrivateRoute>
            <OperatorProvider>
              <QueueProvider>
                <CallProvider>
                  <RecordingProvider>
                    <HotkeysProvider>
                      <ErrorBoundary>
                        <LoadingProvider>
                          <Box
                            background="pureGreyLightest"
                            height="100vh"
                            justifyContent="stretch"
                            alignItems="stretch"
                          >
                            <SideBar />
                            <Stack height="100%" fullWidth vertical>
                              <CallBar />
                              <Box width="100%" scroll="vertical">
                                <Routes>
                                  <Route path={`${routes.incident}`} element={<IncidentPage />} />
                                  <Route path={routes.start} element={<HomePage />} />
                                  <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                              </Box>
                            </Stack>
                          </Box>
                        </LoadingProvider>
                        {envVars().IS_DEBUG_MODE === "true" && <DebugInfo />}
                      </ErrorBoundary>
                    </HotkeysProvider>
                  </RecordingProvider>
                </CallProvider>
              </QueueProvider>
            </OperatorProvider>
          </PrivateRoute>
        </SnackbarProvider>
      </DebugProvider>
    </HashRouter>
  )
}

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Box
      background="pureGreyLightest"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Heading level="h1">{t("common.pageNotFound", "Page not found")}</Heading>
    </Box>
  )
}
