import { useHotkeys, useShowHotkeysHelp } from "common"
import { Box } from "components"
import { ColorName } from "components/colors"
import { Text } from "components/typography"

interface HotkeyProps {
  /** The key combination to trigger the callback */
  pattern: string
  /** The callback to execute when the key combination is pressed */
  callback?: () => void
  /** The color for the border and text */
  color?: ColorName
  /** Show the hotkey regardless of the state of the global hotkey visibility .*/
  alwaysVisible?: boolean
  /** Disable the hotkey */
  disabled?: boolean
  /** Add an ID the element */
  id?: string
}
/**
 * Use this component to bind a keyboard combination to a function.
 *
 * By default the component does not render anything, however
 * by pressing the global `control+h` hotkey (which toggles hotkey visibility)
 * it becomes visible and shows the key combination needed to trigger it.
 */
export const Hotkey = ({
  pattern,
  callback,
  color = "white",
  alwaysVisible = false,
  disabled,
  id,
}: HotkeyProps) => {
  const { showHelp } = useShowHotkeysHelp()
  useHotkeys(pattern, callback || (() => {}), [], disabled)

  return showHelp || alwaysVisible ? (
    <Box
      id={id}
      borderColor={color}
      borderWidth="1px"
      padding="2px 4px"
      alignItems="center"
      justifyContent="center"
      radius="4px"
    >
      <Text inline variant="h5" color={color}>
        {pattern.toUpperCase()}
      </Text>
    </Box>
  ) : null
}
