import { css, cx } from "@emotion/css"
import { IconName } from "../icons"
import { ColorName, Colors } from "../colors"

interface IconProps {
  /** The icon to use, see Material Icons documentation
   * for available icons.
   *
   * @link https://fonts.google.com/icons
   * @example "face"
   */
  name: IconName
  /**
   * The size of the icon as a string
   * Defaults to 14px
   *
   * @example "16px"
   */
  size?: string
  /**
   * The color of the icon.
   * Defaults to grey
   */
  color?: ColorName
}

const iconStyle = css`
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  display: flex;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: ${Colors.greyT15};
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
`

/**
 * A simple icon that uses the
 * Google Material Icons typeface.
 *
 * @see https://fonts.google.com/icons
 */
export const Icon = ({ name, size, color }: IconProps) => {
  return (
    <span
      className={cx(
        iconStyle,
        size &&
          css`
            font-size: ${size};
          `,
        color &&
          css`
            color: ${Colors[color]};
          `
      )}
    >
      {name}
    </span>
  )
}
