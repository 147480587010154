/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  postCallStatus,
  envVars,
  Incident,
  useEvents,
  useIncident,
  useSnackbar,
} from "common"
import JsSIP from "jssip"
import { CallOptions, OutgoingRTCSessionEvent } from "jssip/lib/UA"
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"

let socket = new JsSIP.WebSocketInterface(envVars().SIP_WS_URL)

const configuration = {
  sockets: [socket],
  uri: envVars().SIP_URI,
  password: envVars().SIP_PASSWORD,
  hack_via_tcp: true,
  hack_via_ws: true,
}

//TODO - check if we got authenticated
let ua = new JsSIP.UA(configuration)

export type CallStatus = "started" | "pending" | "ended" | "failed" | "notStarted"

interface Call {
  connect: (incident: Incident, isSipDevelopment?: boolean) => void
  disconnect: () => void
  status: CallStatus
  callDevelopmentStatus: CallStatus
  startedAt: Date | undefined
  isConnected: boolean
  incident?: Incident
  login: (URI: string, password: string) => void
}

const CallContext = createContext<Call>({
  connect: (incident: Incident, isSipDevelopment = false) => {},
  disconnect: () => {},
  status: "pending",
  callDevelopmentStatus: "pending",
  startedAt: new Date(),
  isConnected: false,
  incident: undefined,
  login: (URI: string, password: string) => {},
})

export const useCall = () => useContext(CallContext)

export const CallProvider = ({ children }: { children: ReactNode }) => {
  const [callStatus, setCallStatus] = useState<CallStatus>("notStarted")
  const [callDevelopmentStatus, setCallDevelopmentStatus] = useState<CallStatus>("notStarted")
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const mediaConstraints: CallOptions["mediaConstraints"] = { audio: true, video: false }
  const [callStartedAt, setCallStartedAt] = useState<Date>()
  const setToastMessage = useSnackbar()
  const [isConnected, setIsConnected] = useState(false)
  const [isSipDevelopmentMode, setIsSipDevelopmentMode] = useState(false)
  const [sipNumberDevelopmentMode, setSipNumberDevelopmentMode] = useState<string>()
  const [incident, setIncident] = useState<Incident>()

  const { mutate: refreshIncident } = useIncident(incident?.id)
  const { mutate: refreshEvents } = useEvents(incident?.id)

  // Register callbacks to desired call events
  const eventHandlers: CallOptions["eventHandlers"] = {
    failed: async () => {
      if (isSipDevelopmentMode) {
        setCallDevelopmentStatus("failed")
        setIsSipDevelopmentMode(false)
        return
      }

      setCallStatus("failed")
      try {
        // TODO - temporary solution, changed this to a 'callFailed' call when we have it.
        incident && (await postCallStatus(incident?.id, "connected"))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      refreshEvents()
    },
    ended: async () => {
      if (isSipDevelopmentMode) {
        setCallDevelopmentStatus("ended")
        setIsSipDevelopmentMode(false)
        return
      }

      setCallStatus("ended")
      setCallStartedAt(undefined)
      try {
        incident && (await postCallStatus(incident?.id, "disconnected"))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      refreshEvents()
    },
    confirmed: async () => {
      if (isSipDevelopmentMode) {
        setCallDevelopmentStatus("started")
        setIsSipDevelopmentMode(false)
        return
      }

      setCallStatus("started")
      try {
        incident && (await postCallStatus(incident?.id, "connected"))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      refreshEvents()
    },
  }

  const options: CallOptions = {
    eventHandlers,
    mediaConstraints: mediaConstraints,
  }

  useEffect(() => {
    if (!incident || !incident.sipNumber) return

    setCallStatus("pending")
    ua.call(incident.sipNumber, options)
    setCallStartedAt(new Date())
    refreshIncident()
    refreshEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident])

  useEffect(() => {
    if (!isSipDevelopmentMode || !sipNumberDevelopmentMode) return

    setCallDevelopmentStatus("pending")
    ua.call(sipNumberDevelopmentMode, options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSipDevelopmentMode])

  /**
   * Takes/answers/makes a call
   * @param incident SIP callback number
   * @param isSipDevelopment Determine if the call is a test or not
   */
  const connect = (incident: Incident, isSipDevelopment = false) => {
    if (callStatus === "started") {
      setToastMessage("Call already in progress")
      return
    }
    if (!incident || !incident.sipNumber) {
      setToastMessage("Invalid incident")
      return
    }

    if (isSipDevelopment) {
      setIncident(undefined)
      setIsSipDevelopmentMode(isSipDevelopment)
      setSipNumberDevelopmentMode(incident.sipNumber)
    } else {
      setIncident(incident)
    }
  }

  /**
   * Drops/ends a call
   */
  const disconnect = async () => {
    ua.terminateSessions()
  }

  /**
   * Starts a new webRTC session
   */
  const startSession = () => {
    ua.start()

    ua.on("newRTCSession", (data: OutgoingRTCSessionEvent) => {
      const { session } = data

      if (session.direction === "incoming") {
        session.on("peerconnection", (e) => {
          e.peerconnection.addEventListener("track", createAudioFromCall)
        })
        session.answer({ mediaConstraints: mediaConstraints })
      } else {
        session.connection.addEventListener("track", createAudioFromCall)
      }
    })
    // Handlers for server connection
    ua.on("connected", () => {
      setIsConnected(true)
    })
    ua.on("registered", () => {
      setIsConnected(true)
    })

    ua.on("disconnected", () => {
      setIsConnected(false)
    })

    ua.on("connecting", () => {
      setIsConnected(false)
    })

    ua.on("unregistered", () => {
      setIsConnected(false)
    })

    ua.on("registrationFailed", () => {
      setIsConnected(false)
    })

    ua.on("registrationExpiring", () => {
      setIsConnected(false)
    })

    ua.on("sipEvent", () => {
      setIsConnected(true)
    })
  }

  const login = (URI: string, password: string) => {
    ua.terminateSessions()
    socket = new JsSIP.WebSocketInterface(envVars().SIP_WS_URL)
    configuration.uri = URI
    configuration.password = password
    configuration.sockets = [socket]

    ua = new JsSIP.UA(configuration)
    startSession()
  }

  const createAudioFromCall = (event: RTCTrackEvent) => {
    const [stream] = event.streams
    if (audioRef.current) {
      audioRef.current.srcObject = stream
      audioRef.current.play()
    }
  }

  /**
   * Terminates the current webRTC session
   */
  const endSession = () => {
    ua.stop()
  }

  useEffect(() => {
    startSession()
    return endSession
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CallContext.Provider
      value={{
        connect,
        disconnect,
        status: callStatus,
        callDevelopmentStatus: callDevelopmentStatus,
        startedAt: callStartedAt,
        isConnected,
        incident,
        login,
      }}
    >
      <audio ref={audioRef}>
        <track default kind="captions" />
      </audio>
      {children}
    </CallContext.Provider>
  )
}
