import { css } from "@emotion/css"
import { ColorName, Colors } from "components/colors"
import { ReactNode, Ref } from "react"

interface BoxProps {
  children?: ReactNode
  /**
   * Change alignment of children.
   * Defaults to center
   */
  alignItems?: "center" | "start" | "end" | "stretch"
  /**
   * Change justification of children.
   */
  justifyContent?: "center" | "space-around" | "space-between" | "stretch" | "end"
  /**
   * Background color of the box
   */
  background?: ColorName
  /**
   * Color of the border.
   */
  borderColor?: ColorName
  /**
   * Width of the border.
   */
  borderWidth?: string
  /**
   * Style of the border.
   */
  borderStyle?: "dashed" | "solid"
  /**
   * Radius of the border.
   */
  radius?: string
  /**
   * Height of the box.
   */
  height?: string
  /**
   * Max height of the box.
   */
  maxHeight?: string
  /**
   * Min height of the box.
   */
  minHeight?: string
  /**
   * Width of the box.
   */
  width?: string
  /**
   * Max width of the box.
   */
  maxWidth?: string
  /**
   * Minimum width of the box.
   */
  minWidth?: string
  /**
   * Move Box above siblings by increasing z-index
   */
  moveForward?: boolean
  /**
   * Add a padding to the box
   */
  padding?: string
  /**
   * Position decides where the box is placed relative to its parent.
   */
  position?: "relative" | "absolute" | "fixed" | "sticky"
  /** Disallow shrinking of the flexbox  */
  dontShrink?: boolean
  /**
   * Make the box grow to the next element.
   */
  grow?: boolean
  /**
   * Position top
   */
  top?: string
  /**
   * Position right
   */
  right?: string
  /**
   * Position bottom
   */
  bottom?: string
  /**
   * Position left
   */
  left?: string
  /**
   * Make children stack vertically instead of horizontally
   */
  vertical?: boolean
  /**
   * Allow Box to scroll in one direction
   */
  scroll?: "vertical" | "horizontal"
  /**
   * Add a title attribute to the div
   */
  title?: string
  /** Add an ID to the div */
  id?: string
  /** Obtain a ref to the div */
  boxRef?: Ref<HTMLDivElement>
  /** Will clip overflowing content */
  hideOverflow?: boolean
  /** Make the background pulsate */
  pulse?: boolean
  /** Animate changes such as width, opacity etc*/
  transition?: boolean
  /** Tabindex of the box */
  tabIndex?: number
  /**  */
  opacity?: boolean
}

/**
 * Our main layouting component.
 *
 * It provides a typed, well-defined, interface for
 * working with a flexed div
 *
 * Together with `<Stack>` this component allows us to
 * declaratively create pretty much any UI layout needed.
 */
export const Box = ({
  alignItems = "center",
  background,
  borderColor,
  borderWidth = "0",
  radius = "0",
  bottom,
  children,
  height,
  justifyContent,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  padding = "0",
  position,
  right,
  top,
  left,
  vertical,
  width,
  scroll,
  dontShrink,
  grow,
  id,
  title,
  boxRef,
  hideOverflow,
  moveForward,
  pulse,
  transition,
  tabIndex,
  borderStyle = "solid",
  opacity,
}: BoxProps) => (
  <div
    ref={boxRef}
    id={id}
    tabIndex={tabIndex}
    className={css`
      box-sizing: border-box;
      display: flex;
      flex: ${grow ? 1 : undefined};
      align-items: ${scroll === "vertical" ? "flex-start" : alignItems};
      background-color: ${background ? Colors[background] : undefined};
      border-color: ${borderColor ? Colors[borderColor] : "black"};
      border-width: ${borderWidth};
      border-style: ${borderStyle};
      z-index: ${moveForward ? 1 : undefined};
      border-radius: ${radius};
      bottom: ${bottom};
      flex-direction: ${vertical ? "column" : "row"};
      justify-content: ${justifyContent};
      height: ${height};
      max-height: ${maxHeight};
      min-height: ${minHeight};
      width: ${width};
      max-width: ${maxWidth};
      min-width: ${minWidth};
      position: ${position};
      flex-shrink: ${dontShrink ? 0 : undefined};
      padding: ${padding};
      right: ${right};
      top: ${top};
      left: ${left};
      transition: ${transition ? "all 0.2s" : undefined};
      overflow-y: ${scroll === "vertical" ? "scroll" : hideOverflow ? "hidden" : undefined};
      ::-webkit-scrollbar {
        display: none;
      }
      animation: ${pulse ? "pulse 700ms infinite" : undefined};
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      opacity: ${opacity ? "0.6" : undefined};
      &:focus {
        outline: 0.5px solid ${Colors.azure};
      }

      @keyframes pulse {
        0% {
          background-color: ${Colors.twilightRedStrong};
        }
        100% {
          background-color: ${Colors.twilightRedWeak};
        }
      }
    `}
    title={title}
  >
    {children}
  </div>
)
