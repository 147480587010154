import { GetCareRecipient } from "common"
import { ageFromSsn, AutoFit, Card, Header, Information, ProgressBar } from "components"
import { useTranslation } from "react-i18next"
interface CRInfoPanelProps {
  /** The id of the recipient */
  recipientId?: string
  /** Incident/Alarm code to display */
  incidentCode?: string
}

/**
 * This component renders Care Recipient Info Panel.
 */
export const RecipientInfo = ({ recipientId, incidentCode }: CRInfoPanelProps) => {
  const { t } = useTranslation()

  return recipientId ? (
    <GetCareRecipient careRecipientId={recipientId}>
      {({ data: careRecipient, loading }) =>
        careRecipient && !loading ? (
          <Card
            collapsible
            collapseHotkeyPattern="ctrl+i"
            id="RecipientInfo"
            fullWidth
            header={
              <Header
                id="RecipientInfoName"
                subheading={`${ageFromSsn(careRecipient.details?.ssn)} ${t(
                  "common.age",
                  "years old"
                )}`}
                icon="face"
              >
                {`${careRecipient.name}`}
              </Header>
            }
          >
            <AutoFit minWidth="100px">
              <Information
                color="coral"
                icon="info"
                heading={t("recipient.importantInfo", "Important information")}
                maxWidth="300px"
              >
                {careRecipient.details?.importantInfo}
              </Information>
              <Information
                icon="emergency"
                heading={t("recipient.medicalInformation", "Medical information")}
                maxWidth="300px"
              >
                {careRecipient.details?.medicalDetails}
              </Information>
              <Information
                icon="phone"
                heading={t("recipient.contact", "Contact")}
                maxWidth="300px"
              >
                {careRecipient.details?.contact?.phone}
              </Information>
              <Information
                id="CRAlarmCode"
                icon="alarm"
                heading={t("recipient.alarmCode", "Alarm code")}
                maxWidth="300px"
              >
                {incidentCode}
              </Information>
            </AutoFit>
          </Card>
        ) : (
          <Card fullWidth header={t("recipient.heading", "Care recipient")}>
            <ProgressBar />
          </Card>
        )
      }
    </GetCareRecipient>
  ) : (
    <Card fullWidth header={t("recipient.heading", "Care recipient")}>
      <ProgressBar />
    </Card>
  )
}
