import { SLAResponse, useNavigation } from "common"
import { Box, Clickable, ProgressBar, SLATimer } from "components"
import { AlarmPriority, LogItemEvent } from "features"
import { Event } from "generated/api"
import { Incident } from "./common"

interface IncidentWithEventProps {
  /** The time when the incident was created */
  date: Date
  /** The department name associated with this incident */
  department: string
  /** The recipient name associated with this incident */
  recipient: string
  /** The type code of the alarm */
  incidentType: string
  /** The id of the incident*/
  incidentId: string
  /** The latest event of incident */
  latestEvent: Event | undefined
  /** The id of the recipient */
  recipientId: string
  /** SLA object */
  sla?: SLAResponse | undefined
  priority: number
  id?: string
}
/**
 * Display an Incident and events attached to it
 *
 */
export const IncidentWithEvent = ({
  date,
  department,
  recipient,
  incidentType,
  incidentId,
  latestEvent,
  recipientId,
  sla,
  priority,
  id,
}: IncidentWithEventProps) => {
  const navigation = useNavigation()
  return (
    <Clickable fullWidth onClick={() => navigation.goToIncident(recipientId, incidentId)}>
      <Box
        background="aliceBlue"
        borderColor="greyT15"
        borderWidth="1px"
        radius="5px"
        width="100%"
        padding="7px 7px 8px 22px"
        justifyContent="space-between"
        position="relative"
      >
        <Incident
          id={id}
          priority={priority as AlarmPriority}
          showTimer={false}
          small
          incidentType={incidentType}
          date={new Date(date)}
          department={department}
          recipient={recipient}
        />
        <Box
          background="white"
          borderColor="greyT20"
          borderWidth="1px"
          radius="3px"
          height="100%"
          minWidth="60%"
          padding="8px 10px"
        >
          {latestEvent ? <LogItemEvent isIncidentList event={latestEvent} /> : <ProgressBar />}
        </Box>
        <Box position="absolute" right="-8px" top="-8px">
          {sla && <SLATimer sla={sla} />}
        </Box>
      </Box>
    </Clickable>
  )
}
