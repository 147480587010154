import { css } from "@emotion/css"
import { ReactNode } from "react"
import { ColorName, Colors } from "../colors"

interface TextProps {
  children?: ReactNode
  /** The look of the text */
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "normal" | "uppercase"
  /** Make it bold */
  bold?: boolean
  /** Make it even bolder  */
  black?: boolean
  /** Make it light in weight */
  light?: boolean
  /**
   * Render as an inline element (span)
   * instead of paragraph
   */
  inline?: boolean
  /** Make it italic */
  italic?: boolean
  /** The color of the text */
  color?: ColorName
  /** Force upper case rendering of the text */
  upperCase?: boolean
  /** Don't wrap the text */
  noWrap?: boolean
  /** Add an ID to the text element */
  id?: string
  /** Align the text */
  align?: "center" | "left" | "right" | "justify"
  /** Change whitespace setting */
  whiteSpace?: "nowrap" | "pre" | "pre-line" | "pre-wrap" | "normal"
  /** The degree to which the content is hidden */
  opacity?: number
  wordBreak?: boolean
}

const fontSize: Record<Required<TextProps>["variant"], string> = {
  h1: "20px",
  h2: "18px",
  h3: "15px",
  h4: "13px",
  h5: "11px",
  h6: "9px",
  normal: "13px",
  uppercase: "9px",
}

const fontWeight: Record<Required<TextProps>["variant"], string> = {
  h1: "600",
  h2: "600",
  h3: "600",
  h4: "600",
  h5: "600",
  h6: "700",
  normal: "400",
  uppercase: "700",
}

/**
 * This is our main text component.
 *
 * All the text in our app should be wrapped in this component (or `<Heading>`)
 * to ensure that thee text renders correctly (with the correact typeface and sizes)
 */
export const Text = ({
  children,
  variant = "normal",
  italic,
  bold,
  light,
  inline,
  color = "battleShipBlue",
  upperCase,
  noWrap,
  id,
  whiteSpace,
  align,
  opacity,
  black,
  wordBreak,
}: TextProps) => {
  const style = css`
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: ${black ? 700 : bold ? "600" : light ? "300" : fontWeight[variant]};
    font-style: ${italic ? "italic" : "normal"};
    font-size: ${fontSize[variant]};
    color: ${Colors[color]};
    text-transform: ${upperCase || variant === "uppercase" ? "uppercase" : "none"};
    word-wrap: ${noWrap ? "break-word" : "normal"};
    white-space: ${whiteSpace};
    text-align: ${align};
    opacity: ${opacity};
    word-break: ${wordBreak ? "break-all" : "normal"};
    letter-spacing: ${variant === "uppercase" ? "0.5px" : "normal"};
    ::first-letter {
      text-transform: uppercase;
    }
  `

  const SurroundingTag =
    variant === "normal" || variant === "uppercase" ? (inline ? "span" : "p") : variant
  return (
    <SurroundingTag id={id} className={style}>
      {children}
    </SurroundingTag>
  )
}
