import { envVars } from "common/config"
import {
  Account,
  Incident as BackendIncident,
  AlarmEvent,
  Event,
  AlarmState,
  Api,
  Personnel,
  Reason as BackendReason,
  SlaResponse,
  CareRecipient,
  CallStatus
} from "generated"
import Cookies from "js-cookie"
import { createContext, ReactNode, useContext, useState } from "react"
import useSWR from "swr"
import { BareFetcher, PublicConfiguration } from "swr/dist/types"

// Mapping between the backend and the frontend
export type Reason = BackendReason
export type IncidentEvent = AlarmEvent
export type IncidentLogEvent = Event
export type IncidentState = AlarmState
export type IncidentListType = Api.GetLastTypedAlarmList.RequestParams["type"]
export type SLAResponse = SlaResponse
export type Incident = BackendIncident

/** Render prop */
interface RenderProp<DataType> {
  data: DataType | undefined
  loading: boolean
  mutate: (data: DataType) => void
  error: boolean
}

interface LoadingContextProps {
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setIsLoading: () => {},
})

export const useLoading = () => useContext(LoadingContext)

/** Global loading context, used to show loading bar when there is an ongoing fetch */
export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

/** The type of the children prop in FetchComponent */
type FetchComponentChildrenType<DataType> = (props: RenderProp<DataType>) => ReactNode

/** These are the headers that are added to each request made by the frontend */
const headers = {
  authorization: "Bearer " + Cookies.get("Bearer"),
  "Content-Type": "application/json",
}

const poster = async (endpoint: string, body?: Record<string, string>) =>
  fetch(envVars().API_BASE_URL + `/api/v1${endpoint}`, {
    headers,
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((res) => res.text())
    .then((text) => text && JSON.parse(text))

/** Wrapper around useSWR */
const useFetcher = <Datatype,>(
  endpoint?: string,
  options?: Partial<PublicConfiguration<Datatype, unknown, BareFetcher<Datatype>>>
) => {
  const { setIsLoading } = useLoading()

  const { data, error, mutate } = useSWR<Datatype>(
    endpoint && envVars().API_BASE_URL + "/api/v1" + endpoint,
    /** The function that will perform the actual fetching of the backend */
    (url: string) => {
      setIsLoading(true)
      return fetch(url, { headers }).then((res) => {
        setIsLoading(false)
        const contentType = res.headers.get("Content-Type")
        if (contentType?.includes("audio") && res.status === 200) {
          return res
            .blob()
            .then((blob) => URL.createObjectURL(blob))
            .then((url) => url)
        }
        return res.json()
      })
    },
    options
  )

  return { data, loading: !data && !error, mutate, error }
}

/** Returns all the incidents */
export const useIncidents = (
  queryParam: {
    type?: IncidentListType
    careRecipientId?: string
  },
  options?: Partial<PublicConfiguration<Incident[], unknown, BareFetcher<Incident[]>>>
) => {
  let endpoint
  if (queryParam.type) {
    endpoint = `/incidents?type=${queryParam.type}`
  } else if (queryParam.careRecipientId) {
    endpoint = `/carerecipient/${queryParam.careRecipientId}/incidents`
  }
  return useFetcher<Incident[]>(endpoint, options)
}

/** Fetch component that returns all the incidents */
export const GetIncidents = ({
  children,
  type,
  careRecipientId,
  refreshInterval,
}: {
  children: FetchComponentChildrenType<Incident[]>
  type?: IncidentListType
  careRecipientId?: string
  refreshInterval?: number
}) => <>{children(useIncidents({ type, careRecipientId }, { refreshInterval }))}</>

/** Returns all the events for a specific incidents */
export const useEvents = (incidentId?: string, refreshInterval?: number) =>
  useFetcher<IncidentLogEvent[]>(incidentId && `/incidents/${incidentId}/events`, { refreshInterval })
/** Functional component that returns all the events for a specific incidents */
export const GetEvents = ({
  children,
  incidentId,
  refreshInterval,
}: {
  children: FetchComponentChildrenType<IncidentLogEvent[]>
  incidentId?: string
  refreshInterval?: number
}) => <>{children(useEvents(incidentId, refreshInterval))}</>

/** Hook that returns a care recipient */
export const useCareRecipient = (careRecipientId?: string) =>
  useFetcher<CareRecipient>(careRecipientId && `/carerecipient/${careRecipientId}`)
/** Functional component that returns a care recipient */
export const GetCareRecipient = ({
  children,
  careRecipientId,
}: {
  children: FetchComponentChildrenType<CareRecipient>
  careRecipientId?: string
}) => <>{children(useCareRecipient(careRecipientId))}</>

/** Function that returns the next incident in incident queue */
export const nextIncident = (): Promise<Incident> => poster(`/Incidents/next-incident`)

/** Creating a new incident used for remote visit */
export const createIncident = (incidentCode: string): Promise<Incident> =>
  poster(`/incidents/${incidentCode}`)

/** Function that starts or ends the call */
export const postCallStatus = (incidentId: string, status: CallStatus): Promise<Incident> =>
  poster(`/incidents/${incidentId}/call?callStatus=${status}`)

/** Returns the currently logged in operator's account */
export const useCurrentAccount = () =>
  useFetcher<Account>("/account/current", { revalidateOnFocus: false })

/** Returns a personnel */
export const usePersonnel = (personnelId: string) =>
  useFetcher<Personnel>(personnelId && `/personnel/${personnelId}`)

/** Returns an incident  */
export const useIncident = (incidentId?: string, refreshInterval?: number) =>
  useFetcher<Incident>(incidentId && `/incidents/${incidentId}`, { refreshInterval })

export const useGetRecording = (incidentId?: string) =>
  useFetcher<string>(incidentId && `/call/playaudiofile/${incidentId}`)

/** Transfer an incident */
export const transferIncident = (incidentId?: string, note?: string) =>
  poster(`/incidents/${incidentId}/transfer`, { note: note || "" })

/** Closing an incident */
export const closeIncident = (incidentId: string, note?: string) =>
  poster(`/incidents/${incidentId}/close`, { note: note || "" })

/** Add note to an incident*/
export const addNote = (incidentId: string, note: string) =>
  poster(`/incidents/${incidentId}/add-note?note=${note}`)

/** Add Reason to an incident*/
export const addReason = (incidentId: string, reasonId: string, note?: string) =>
  poster(`/incidents/${incidentId}/set-reason`, { reasonId, note: note || "" })

/** Returns reasons for an incident */
export const useReasons = () => useFetcher<Reason[]>("/reason")

export const GetReasons = ({ children }: { children: FetchComponentChildrenType<Reason[]> }) => (
  <>{children(useReasons())}</>
)
