import { css } from "@emotion/css"
import { Colors } from "components/colors"
import { Hotkey } from "components/interaction"
import { useRef } from "react"
import Select from "react-select"
import { Stack } from "../layout/Stack"
import { Text } from "../typography/Text"

interface SelectOptions {
  /**
   * The label to show the user
   */
  label: string
  /**
   * The value of this option
   */
  value: string
}
interface SelectProps {
  /**
   * An array of option objects.
   * The `label` will be displayed in the select box.
   */
  options: SelectOptions[]
  /**
   * Get the newly selected value.
   *
   * Returns the `value` of the selected option object
   */
  onChange: (reasonId: string) => void
  /**
   * Will focus this element on load
   */
  autoFocus?: boolean
  /**
   * Label for the input.
   *
   * Shows above the select box and affords clicking on
   */
  label: string
  /**
   * The ID for the select element
   */
  id: string
  /** Hotkey pattern */
  focusHotkeyPattern?: string
  /** Value for the selected value  */
  value?: string
  /** Text for the placeholder */
  placeholder?: string
}

const containerStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const selectStyle = css`
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
`

/**
 * A simple Select input component that just wraps
 * the standard HTML element.
 */
export const SearchSelect = ({
  options,
  onChange,
  autoFocus,
  label,
  id,
  focusHotkeyPattern,
  value,
  placeholder,
}: SelectProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null)

  const customStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      fontSize: 13,
      fontWeight: 600,
      minHeight: "32px",
      height: "32px",
      color: Colors["greyT5"],
      borderColor: Colors["greyT10"],
      "&:hover": {
        borderColor: Colors["greyT10"],
      },
    }),
    menu: (provided: Record<string, unknown>) => ({
      ...provided,
      maxHeight: 155,
      borderColor: Colors["grey"],
    }),
    menuList: (provided: Record<string, unknown>) => ({
      ...provided,
      maxHeight: 155,
      color: Colors["greyT5"],
      fontSize: 13,
    }),
    singleValue: (provided: Record<string, unknown>) => ({
      ...provided,
      color: Colors["greyT5"],
    }),
    input: (provided: Record<string, unknown>) => ({
      ...provided,
      color: Colors["greyT5"],
      fontSize: 13,
      cursor: "text",
    }),
    valueContainer: (provided: Record<string, unknown>) => ({
      ...provided,
      height: "32px",
    }),
    indicatorsContainer: (provided: Record<string, unknown>) => ({
      ...provided,
      height: "32px",
    }),
    dropdownIndicator: (provided: Record<string, unknown>) => ({
      ...provided,
      svg: {
        fill: Colors["greyT10"],
        stroke: Colors["greyT10"],
      },
      color: Colors["greyT10"],
      "&:hover": {
        cursor: "pointer",
      },
    }),
  }

  return (
    <div className={containerStyle}>
      <Stack spacing="5px" vertical>
        <label
          htmlFor={id}
          className={css`
            min-height: 19px;
            display: flex;
          `}
        >
          <Stack spacing="8px" alignItems="end">
            <Text variant="h5" color="grey">
              {label}
            </Text>
            {focusHotkeyPattern && (
              <Hotkey
                pattern={focusHotkeyPattern}
                callback={() => selectRef.current.focus()}
                color="azure"
              />
            )}
          </Stack>
        </label>
        <Select
          placeholder={placeholder}
          menuPlacement="top"
          filterOption={(option, inputValue) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase())
          }}
          id={id}
          classNamePrefix={id}
          ref={selectRef}
          styles={customStyles}
          className={selectStyle}
          value={options.find((option) => option.value === value) || null}
          autoFocus={autoFocus}
          onChange={(option) => onChange(option?.value || "")}
          defaultValue={{ label: "", value: "" }}
          options={options}
          openMenuOnFocus
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: Colors["aliceBlue3"],
              primary: Colors["azure"],
              neutral180: Colors["greyT10"],
            },
          })}
        />
      </Stack>
    </div>
  )
}
