import { css } from "@emotion/css"
import React, { Dispatch, ReactNode, SetStateAction } from "react"
import { Button } from "../ui/Button"
import { Text } from "../typography/Text"
import { Colors } from "components/colors"

interface TabProps {
  /** The visible title of the tab */
  children: ReactNode
  /** The identifier of the tab */
  title: string
  /** Active tab  */
  activeTab: string | undefined
  /** The callback to execute when the button is clicked*/
  setActiveTab: Dispatch<SetStateAction<string>>
  /** Pattern for hotkey */
  hotkey?: string
  /** Id for playwright tests*/
  id?: string
}
/**
 * A simple tab component.
 */
export const Tab = ({ children, title, activeTab, setActiveTab, hotkey, id }: TabProps) => {
  return (
    <div
      id={id}
      className={css`
        border-bottom: ${title === activeTab ? `2px solid ${Colors.battleShipBlue}` : "none"};
      `}
    >
      <Button
        noOutline
        tabIndex={-1}
        hotkey={hotkey}
        variant="text"
        onClick={() => setActiveTab(title)}
      >
        <Text variant="h3">{children}</Text>
      </Button>
    </div>
  )
}
