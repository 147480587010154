import { GetIncidents, Incident, IncidentListType } from "common"
import { Box, Card, Icon, ProgressBar, Stack, Text } from "components"
import { IconName } from "components/icons"
import { IncidentWithEvent } from "./IncidentWithEvent"

interface IncidentListProps {
  /** Type of incidents to show */
  incidentType: IncidentListType
  /** Title to the card that lists the incidents */
  title: string
  /** Icon that will be to the left of the title */
  icon: IconName
  /** Id for playwright tests */
  id?: string
  /** Hotkey pattern */
  focusHotkeyPattern?: string
}

/**
 * Render an array of incidents as a list using the Card Component
 */
export const IncidentList = ({
  incidentType,
  title,
  icon,
  id,
  focusHotkeyPattern,
}: IncidentListProps) => {
  /**
   * Sorting function to sort incidents by the time of their last event
   */
  const byLatestEvent = (a: Incident, b: Incident) => {
    if (a.latestEvent && b.latestEvent) {
      return (
        new Date(b.latestEvent.createdDate).getTime() - new Date(a.latestEvent.createdDate).getTime()
      )
    }
    return 0
  }
  return (
    <GetIncidents type={incidentType} refreshInterval={10000}>
      {({ data: incidents, loading }) => (
        <Card
          contentHeight="calc(100vh - 220px)"
          focusHotkeyPattern={focusHotkeyPattern}
          grow
          header={
            <Stack fullWidth alignItems="center" justifyContent="space-between">
              <Stack alignItems="center" spacing="20px">
                <Icon size="28px" name={icon} />
                <Text variant="h1">{`${title} ${incidents?.length || ""}`}</Text>
              </Stack>
            </Stack>
          }
        >
          <Stack id={id} vertical fullWidth spacing="20px">
            {loading ? (
              <Box width="100%" alignItems="center" justifyContent="center" grow>
                <ProgressBar />
              </Box>
            ) : (
              <>
                {incidents &&
                  incidents.sort(byLatestEvent).map((incident) => {
                    return (
                      <IncidentWithEvent
                        id="IncidentListCRName"
                        priority={incident.priority}
                        sla={incident.ongoingSla}
                        recipientId={incident.careRecipients == null ? "" : incident.careRecipients[0].id}
                        key={incident.id}
                        date={new Date(incident.createdTime)}
                        department={incident.departmentName || ""}
                        incidentType={incident.typeDescription}
                        recipient={
                          incident.careRecipients[0].name || "Name missing"
                        }
                        incidentId={incident.id}
                        latestEvent={incident.latestEvent}
                      />
                    )
                  })}
              </>
            )}
          </Stack>
        </Card>
      )}
    </GetIncidents>
  )
}
