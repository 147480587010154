import { Dispatch, SetStateAction, useEffect, useState } from "react"

/**
 * Gets the value from local storage and returns it as the given type.
 * If the value is not found, then undefined is returned.
 *
 * @param key the key where the value is stored in local storage
 * @returns the value stored in local storage with type T or undefined
 */
const getValueFromLocalStorage = <T extends boolean | number | string>(
  key: string
): T | undefined => {
  const value = localStorage.getItem(key)

  if (value !== null) return JSON.parse(value) as T
  else return undefined
}

/**
 * Similar to a regular react useState, but the state is synced to local storage.
 * Meaning that if the user closes the tab, the state is still there.
 *
 * @param key the key where the value is stored in local storage
 * @param defaultValue the value to use if the value is not found in local storage
 */
export const usePersistentState = <T extends boolean | number | string>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const localStorageValue = getValueFromLocalStorage<T>(key)
  const [value, setValue] = useState<T>(
    localStorageValue === undefined ? defaultValue : localStorageValue
  )

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [value, key])
  return [value, setValue]
}
