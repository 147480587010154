import { css } from "@emotion/css"
import { Hotkey } from "components/interaction"
import { useRef } from "react"
import { Colors } from "../colors"
import { Stack } from "../layout/Stack"
import { Text } from "../typography/Text"

interface TextAreaProps {
  /** The ID used by the Label and any test framework used */
  id: string
  /** The label to show above the input */
  label: string
  /** The value to be displayed in the input */
  value?: string
  /** A callback that will be called when the user inputs something (it is debounced) */
  onChange(val: string): void
  /**
   * Make the Text area take up the entire width of its parent
   */
  fullWidth?: boolean
  /**
   * Make the Text area take up the entire height of its parent
   */
  fullHeight?: boolean
  /** Hotkey pattern */
  focusHotkeyPattern?: string
}

const inputStyle = css`
  padding: 7px 9px;
  border: 1px solid ${Colors.greyT10};
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: ${Colors.greyT5};
  padding-right: 27px;
  width: 100%;
  height: 100%;
`

/**
 * A simple text area component
 */
export const TextArea = ({
  id,
  label,
  value,
  onChange,
  fullWidth = true,
  fullHeight = true,
  focusHotkeyPattern,
}: TextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  return (
    <Stack vertical spacing="5px" fullWidth={fullWidth} fullHeight={fullHeight}>
      <label
        htmlFor={id}
        className={css`
          min-height: 19px;
          display: flex;
        `}
      >
        <Stack spacing="8px" alignItems="end">
          <Text variant="h5" color="grey">
            {label}
          </Text>
          {focusHotkeyPattern && (
            <Hotkey
              pattern={focusHotkeyPattern}
              callback={() => ref.current?.focus()}
              color="azure"
            />
          )}
        </Stack>
      </label>
      <textarea
        ref={ref}
        id={id}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        className={inputStyle}
      ></textarea>
    </Stack>
  )
}
