import { Clickable, Text } from "components"

interface ActionTextProps {
  /** Highlight the text  */
  active?: boolean
  /** The text to display */
  children: string
  /** Disable the action */
  disabled?: boolean
  /** Callback to be called when text is clicked */
  onClick?: () => void
}
/**
 * Renders a text that is clickable, indicating that an action can be taken by the user.
 */
export const ActionText = ({ active, children, disabled, onClick }: ActionTextProps) => {
  return (
    <Clickable onClick={!disabled ? onClick : undefined}>
      <Text align="center" bold color={active ? "azure" : disabled ? "greyT10" : "battleShipBlue"}>
        {children}
      </Text>
    </Clickable>
  )
}
