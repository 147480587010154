import { Incident } from "common"
import { Button, Icon, Stack, Text } from "components"
import { useTranslation } from "react-i18next"

interface ChooseActionProps {
  incident: Incident
  /** The callback to execute when an incident is sent to staff */
  transferIncident: (note: string) => void
  /** The callback to execute to close the incident  */
  closeIncidentMessage: (note: string) => void
  /** Disables transfer and close button */
  disabled: boolean
  /** The callback to execute when a transfer is sent to staff */
  addNote: (note: string) => void
}
/**
 * This component handles transfer, close and notes actions for an incidents.
 *  * TODO: Add buttons for call.
 */
export const ChooseAction = ({
  disabled,
  transferIncident,
  closeIncidentMessage,
}: ChooseActionProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Stack
        alignItems="start"
        justifyContent="center"
        fullHeight
        fullWidth
        padding="12px"
        spacing="28px"
      >
        <Button
          id="TransferIncidentButton"
          hotkey="ctrl+1"
          disabled={disabled}
          variant="primary"
          onClick={() => transferIncident("")}
        >
          <Icon color="white" name="emergency" />
          <Text color="white" bold>
            {t("incident.transferToStaff", "Transfer to staff")}
          </Text>
        </Button>
        <Button
          id="CloseIncidentButton"
          disabled={disabled}
          hotkey="ctrl+e"
          onClick={() => closeIncidentMessage("")}
          variant="important"
        >
          {t("incident.close", "Close incident")}
        </Button>
      </Stack>
    </>
  )
}
