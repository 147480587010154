import { Icon, Stack, Text } from "components"
import { IconName } from "components/icons"

interface PersonBadgeProps {
  /**
   * @todo Get role from api
   */
  role?: "staff" | "operator"
  /* name of the person */
  name?: string
}

const roleToIcon: Record<Required<PersonBadgeProps>["role"], IconName> = {
  staff: "badge",
  operator: "support_agent",
}

export const PersonBadge = ({ role, name }: PersonBadgeProps) => {
  return (
    <Stack spacing="4px" alignItems="center">
      {role && name?.trim() !== "" && (
        <Icon
          size="14px"
          color={role === "operator" ? "brown" : "greyT5"}
          name={roleToIcon[role]}
        />
      )}
      <Text color={role === "operator" ? "brown" : "battleShipBlue"} light>
        {name}
      </Text>
    </Stack>
  )
}
