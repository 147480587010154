import { ColorName } from "components"
import { Box } from "../../layout/Box"
import { Text } from "../../typography/Text"

/**
 * Pads the number with leading zeros if the number is less than 10
 * @param num {number} the number to pad
 * @returns {string} the padded number
 */
export const pad = (num: number) => {
  return num.toString().length === 1 ? `0${num}` : num.toString()
}

/**
 *
 * Formats a number of seconds into a string like 01:02:03
 * @param {number} duration of seconds
 * @returns {string} like "1h 2m 3s" or "01:02:03"
 */
export const formatSeconds = (duration: number): string => {
  const hrs = Math.floor(duration / 3600)
  const mins = Math.floor((duration % 3600) / 60)
  const secs = Math.floor(duration % 60)
  return `${hrs > 0 ? pad(hrs) + ":" : ""}${pad(mins)}:${pad(secs)}`
}

type TimeProps = {
  /** The length of time */
  seconds: number
  /** Display a colored border around the component */
  border?: ColorName
  /** Show the time as disabled */
  disabled?: boolean
}

/**
 * Show a length of time (in seconds) in a human readable fashion.
 * The output will render as hh:mm:ss
 *
 * @example 1200 seconds becomes 20:00
 */
export const Time = ({ seconds, border, disabled }: TimeProps) => (
  <Box
    background={disabled ? "pureGreyLight" : "aliceBlue3"}
    padding="7px 10px"
    radius="4px"
    justifyContent="center"
    alignItems="center"
    borderWidth="1px"
    borderColor={border || "transparent"}
  >
    <Text color={disabled ? "pureGreyDark" : "battleShipBlue"} variant="h5" bold>
      {formatSeconds(seconds)}
    </Text>
  </Box>
)
