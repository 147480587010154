import { css } from "@emotion/css"
import { envVars } from "common"
import { Box, Stack, Text } from "components"
import { Icon } from "."

interface MapProps {
  /** Longitude and latitude */
  coordinates?: [number | undefined, number | undefined]
  /** Height component of the map image's resolution. */
  height?: number
  /** Width component of the map image's resolution. */
  width?: number
  street?: string
  city?: string
}

/** This component renders a map using google static maps. */
export const Map = ({ coordinates, height = 300, width = 300, street, city }: MapProps) => (
  <Box
    borderColor="greyT20"
    radius="6px"
    borderWidth="1px"
    justifyContent="center"
    maxHeight={`${height}px`}
    maxWidth={`${width}px`}
    hideOverflow
    position="relative"
  >
    {coordinates && coordinates?.filter(Boolean).length === 2 && (
      <img
        className={css`
          width: 100%;
          height: 100%;
        `}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${coordinates[1]},${coordinates[0]}&zoom=14&size=${width}x${height}&key=${envVars().REACT_APP_STATIC_MAP}&scale=2&markers=color:red|${coordinates[1]},${coordinates[0]}&style=feature:poi|element:labels|visibility:off`}
        alt="Map"
      />
    )}
    {street && city && (
      <Box
        position="absolute"
        top="10px"
        left="10px"
        background="white"
        borderColor="greyT20"
        borderWidth="1px"
        radius="6px"
        padding="10px"
      >
        <Stack spacing="8px" alignItems="center">
          <Icon size="28px" name="house" />
          <Stack vertical spacing="2px">
            <Text variant="h3">{street}</Text>
            <Text>{city}</Text>
          </Stack>
        </Stack>
      </Box>
    )}
  </Box>
)
