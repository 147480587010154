import { css } from "@emotion/css"
import { Box } from "../layout/Box"
import { ColorName, Colors } from "../colors"
import { useEffect, useRef } from "react"

interface ProgressBarProps {
  /** Percentage of progress (0-100). If omitted, the progressbar will pulsate. */
  value?: number
  /** Color of the background behind the bar. */
  background?: ColorName
  /** Color of the bar. */
  color?: ColorName
  /** Decides the direction the progressbar moves. If not inverted it runs to the right. */
  inverted?: boolean
  /** Enable full width */
  fullWidth?: boolean
  /** Removes radius of the progressbar */
  noRadius?: boolean
}

/**
 * Display a progress bar.
 * The progress is set using the value property. If no value is given, the progressbar will pulsate.
 */
export const ProgressBar = ({
  inverted,
  value,
  background = "transparent",
  color = "aliceBlue3",
  fullWidth = false,
  noRadius,
}: ProgressBarProps) => {
  const progressRef = useRef<HTMLDivElement>(null)

  /** Gets the current value of the progressbar
   * (This function is only used when the progressbar is pulsating)
   * @param {HTMLDivElement}  progressBar - the div representing the progressbar
   * @returns The integer percentage width of the progressbar. A number between 0 and 100.
   */
  const getCurrentValue = (progressBar: HTMLDivElement) => {
    const width = progressBar.style.width
    if (!width.includes("%")) return 1
    return parseInt(width.replace("%", ""))
  }
  /** Calculates the increment of the given value
   * (This function is only used when the progressbar is pulsating)
   * @param {number} value - the value to calculate the increment for
   * @returns The increment of the value in the range [0, 100].
   */
  const calculateNextValue = (currentValue: number) => {
    const nextValue = currentValue + (inverted ? -1 : 1)
    if (nextValue < 0) return 100
    if (nextValue > 100) return 0
    return nextValue
  }
  /** Sets the given value as the width of the given div
   * (This function is only used when the progressbar is pulsating)
   * @param {HTMLDivElement} progressBar - the div representing the progressbar
   * @param {number} value - the value to set as the width of the progressbar
   */
  const setNextValue = (progressBar: HTMLDivElement, value: number) =>
    (progressBar.style.width = `${value}%`)

  useEffect(() => {
    // If `value` has been given, then we don't need to animate the progressbar.
    if (value !== undefined) return
    const interval = setInterval(() => {
      if (!progressRef.current) return
      const currentValue = getCurrentValue(progressRef.current)
      const nextValue = calculateNextValue(currentValue)
      setNextValue(progressRef.current, nextValue)
    }, 1000 / 60) // 60 times per second

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Because the ref begins as null, we need to wait until it is set.
    if (progressRef.current === null) return

    if (value) progressRef.current.style.width = `${inverted ? 100 - (value % 100) : value % 100}%`

    // This is not done with emotion because it will generate a new style every time it changes.
    progressRef.current.style.backgroundColor = Colors[color]
  }, [value, color, inverted])

  const containerStyle = css`
    width: 100%;
    height: 100%;
    height: 4px;
    ${!fullWidth && "max-width: 200px;"}
    background: ${Colors[background]};
    border-radius: ${noRadius ? "0px" : "10px"};
    overflow: hidden;
  `
  const barStyle = css`
    height: 100%;
    border-radius: ${noRadius ? "0px" : "10px"};
  `

  return (
    <Box id="progressBar" width="100%" height="100%" alignItems="center" justifyContent="center">
      <div className={containerStyle}>
        <div className={barStyle} ref={progressRef} />
      </div>
    </Box>
  )
}
