/**
 * just for padding hours and minutes with leading 0
 * @param val
 * @returns {string}
 */
export const pad = (val: number) => {
  return val.toString().length === 1 ? `0${val}` : val.toString()
}

/**
 *
 * Formats a number of seconds into a string like:
 *
 * a) 1h 2m 3s if delimited is "hms"
 *  This format skips a unit if it is not needed. E.g. the input is 3605 and only return "1h 5s"
 *
 * b) 01:02:03 if ":".
 * @param {number} duration of seconds
 * @returns {string} like "1h 2m 3s" or "01:02:03"
 */
export const formatSeconds = (duration: number, delimiter: "hms" | ":"): string => {
  const hrs = Math.floor(duration / 3600)
  const mins = Math.floor((duration % 3600) / 60)
  const secs = Math.floor(duration % 60)
  return delimiter === "hms"
    ? `${hrs > 0 ? hrs + "h " : ""}${mins > 0 ? mins + "m " : ""}${secs > 0 ? secs + "s" : ""}`
    : `${hrs > 0 ? pad(hrs) + ":" : ""}${pad(mins)}:${pad(secs)}`
}
