import { ReactNode } from "react"
import { ColorName } from "../colors"
import { Text } from "./Text"

interface HeadingProps {
  /** Specify which level this heading belongs to */
  level?: "h1" | "h2" | "h3" | "h4" | "h5"
  /** The heading text */
  children?: ReactNode
  /** The color of the text */
  color?: ColorName
  /** Add an ID to the element */
  id?: string
}

/**
 * This component is used for our all headings in our app.
 *
 * All the text headings in our app should be wrapped in this component (or, for non-headings, `<Text>`)
 * to ensure that thee text renders correctly (with the correact typeface and sizes)
 *
 * For more a complex heading (with subheaders, icons, etc), check out `<Header>`
 */
export const Heading = ({ children, color = "battleShipBlue", level = "h4", id }: HeadingProps) => (
  <Text id={id} color={color} variant={level}>
    {children}
  </Text>
)
