/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from "react"

/**
 * By using this hook we can get the browser to splash a warning before reloading the page
 *
 * @param fn init with fn, so that type checkers won't assume that current might be undefined
 */
export const useUnload = (fn: any) => {
  const cb = useRef(fn)

  useEffect(() => {
    cb.current = fn
  }, [fn])

  useEffect(() => {
    const onUnload = (...args: any[]) => cb.current?.(...args)
    window.addEventListener("beforeunload", onUnload)
    return () => window.removeEventListener("beforeunload", onUnload)
  }, [cb])
}
