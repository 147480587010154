import { Toast } from "components"
import { createContext, ReactNode, useContext, useRef, useState } from "react"

const SnackbarContext = createContext((text: string, timeout?: number) => {})
export const useSnackbar = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const closeSnackbarTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  /**
   * Close Snackbar
   */
  const closeSnackbar = () => {
    setIsSnackbarOpen(false)
    if (closeSnackbarTimeoutRef.current) {
      clearTimeout(closeSnackbarTimeoutRef.current)
    }
  }
  /**
   * Open Snackbar with text
   * @param {string} text text to display
   * @param {string} timeout close Snackbar after ms. Set to 0 if it should stay open
   */
  const openSnackbar = (text: string, timeout = 3000) => {
    setSnackbarMessage(text)
    setIsSnackbarOpen(true)
    if (closeSnackbarTimeoutRef.current) {
      clearTimeout(closeSnackbarTimeoutRef.current)
    }
    if (timeout > 0) {
      closeSnackbarTimeoutRef.current = setTimeout(closeSnackbar, timeout)
    }
  }

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {isSnackbarOpen && <Toast onClose={closeSnackbar}>{snackbarMessage}</Toast>}
      {children}
    </SnackbarContext.Provider>
  )
}
