import { css, cx } from "@emotion/css"
import { ReactNode } from "react"

interface StackProps {
  children?: ReactNode
  /**
   * Stack children vertically instead of horizontally
   */
  vertical?: boolean
  /**
   * The distance between children.
   * Defaults to 2 (8px)
   */
  spacing?: string
  /**
   * Make the Stack span the entire width of its parent
   */
  fullWidth?: boolean
  /**
   * Make the Stack take up the entire height of its parent
   */
  fullHeight?: boolean
  /**
   * Change alignment of children.
   * Defaults to start (ie. top for vertical and left for horizontal)
   */
  alignItems?: "center" | "start" | "end" | "stretch"
  /**
   * Change justification of children.
   */
  justifyContent?:
    | "space-around"
    | "space-between"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-evenly"
  /**
   * Adds padding to all four sides of the Stack
   */
  padding?: string
  /**
   * Keep Stack from shrinking
   */
  dontShrink?: boolean
  /** Allow the children to wrap */
  wrap?: boolean
  /**
   * Set a fixed height on the card
   */
  height?: string
  /** Add an ID to the wrapping div */
  id?: string
}

const verticalStyle = (margin: string) => css`
  flex-direction: column;
  & > * {
    margin-bottom: ${margin} !important;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`

const horizontalStyle = (margin: string) => css`
  flex-direction: row;
  & > * {
    margin-right: ${margin} !important;
  }
  & > *:last-child {
    margin-right: 0 !important;
  }
`

/**
 * Stack children with a space between them.
 *
 * This allows us to avoid using margins on our components while at the same
 * time providing a declarative solution for laying out things horizontally or vertically.
 * Together with `<Box>` this allows us to create pretty much any UI layout.
 *
 * By default it Will horizontally stack children with 0px spacing.
 */
export const Stack = ({
  vertical,
  wrap,
  children,
  alignItems = "start",
  fullWidth,
  fullHeight,
  spacing = "0px",
  justifyContent,
  padding,
  dontShrink,
  height,
  id,
}: StackProps) => (
  <div
    id={id}
    className={cx(
      css`
        display: flex;
        width: ${fullWidth ? "100%" : undefined};
        height: ${fullHeight ? "100%" : undefined};
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        flex-shrink: ${dontShrink ? 0 : undefined};
        flex-wrap: ${wrap ? "wrap" : undefined};
        padding: ${padding};
        height: ${height};
      `,
      vertical ? verticalStyle(spacing) : horizontalStyle(spacing)
    )}
  >
    {children}
  </div>
)
