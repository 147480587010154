import { css } from "@emotion/css"
import { Box } from "components"
import { Colors } from "../colors"
import React, { ChangeEvent } from "react"

interface MediaRangeSliderProps {
  /** The percentage of the slider  */
  value: number
  /** Function set the value of the slider */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}
/** This component renders the slider in the audio playback bar */
export const MediaRangeSlider = ({ value, onChange }: MediaRangeSliderProps) => {
  const inputStyle = css`
    width: 100%;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: ${Colors.white};
    outline: none;
    position: relative;
    cursor: pointer;
    ::after {
      content: "";
      position: absolute;
      right: -4px;
      top: -8px;
      height: 20px;
      width: 4px;
      background: white;
    }
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 26px;
      background: #fff;
      cursor: pointer;
      border: 3px solid #009afd;
      border-radius: 8px;
    }
    &::-moz-range-thumb {
      width: 8px;
      height: 22px;
      background: #fff;
      cursor: pointer;
      border-radius: 4px;
      border: 3px solid #009afd;
      border-radius: 4px;
    }
  `

  return (
    <Box width="100%" height="100%" alignItems="center" justifyContent="center">
      <input
        step={0.1}
        className={inputStyle}
        value={value}
        type="range"
        min="1"
        max="100"
        onChange={(e) => onChange(e)}
      />
    </Box>
  )
}
