import { IncidentState as IncidentStateType } from "common"
import { Box, ColorName, Text } from "components"
import { TFunction, useTranslation } from "react-i18next"

interface IncidentStateProps {
  /** State of the incident  */
  state?: IncidentStateType
  closedWithAction?: boolean
  /** Id for the playwright tests*/
  id?: string
}

interface IncidentStyleInterface {
  background: ColorName
  text: ColorName
  border: ColorName
  title: TFunction<"translation", undefined>
  style: "solid" | "dashed"
}

/**
 * This component is used to display the state of the incident
 */
export const IncidentState = ({ state, closedWithAction }: IncidentStateProps) => {
  const { t } = useTranslation()
  /**
   * Map our incident state to human readable string
   */
  const IncidentStyle: Record<IncidentStateType, IncidentStyleInterface> = {
    new: {
      background: "transparent",
      text: "pureGreyDark",
      border: "pureGreyLight",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    waitForAccept: {
      background: "transparent",
      text: "pureGreyDark",
      border: "pureGreyLight",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    queued: {
      background: "transparent",
      text: "pureGreyDark",
      border: "pureGreyLight",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    queuedApp: {
      background: "transparent",
      text: "coral",
      border: "coral",
      style: "dashed",
      title: t("incident.waitingForAcceptance", "Waiting for acceptance"),
    },
    queuedInArc: {
      background: "transparent",
      text: "pureGreyDark",
      border: "pureGreyLight",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    handledByOperator: {
      background: "transparent",
      text: "green",
      border: "green",
      style: "solid",
      title: t("incident.handledByOperator", "Handle by operator"),
    },

    acceptedByCareStaff: {
      background: "transparent",
      text: "green",
      border: "green",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    staffOnSite: {
      background: "transparent",
      text: "green",
      border: "green",
      style: "solid",
      title: t("incident.handledByStaff", "Handle by staff"),
    },
    closed: {
      background: "transparent",
      text: closedWithAction ? "grey" : "pureGreyDark",
      border: closedWithAction ? "greyT5" : "pureGreyDark",
      style: closedWithAction ? "solid" : "dashed",
      title: closedWithAction
        ? t("incident.closed", "closed")
        : t("incident.noAction", "No action"),
    },
  }

  const style = state && IncidentStyle[state]
  return (
    <Box
      padding="2px 11px"
      height="19px"
      minWidth="133px"
      background={style?.background}
      borderColor={style?.border}
      borderWidth="1px"
      radius="5px"
      justifyContent="center"
      borderStyle={style?.style}
    >
      <Text color={style?.text} variant="uppercase" black>
        {typeof style?.title === "string" && style?.title}
      </Text>
    </Box>
  )
}
