import { IncidentLogEvent } from "common"
import React, { createContext, ReactNode, useContext, useState } from "react"

interface Recording {
  events: IncidentLogEvent[]
  audioSrc?: string
  isPlaying: boolean
  timeStamp: number
  clearRecording: () => void
  setRecording: (audioSrc: string) => void
  setTimeStamp: (timeStamp: number) => void
  setIsPlaying: (isPlaying: boolean) => void
}

const RecordingContext = createContext<Recording>({
  events: [],
  audioSrc: undefined,
  isPlaying: false,
  timeStamp: 0,
  clearRecording: () => {},
  setRecording: (audioSrc: string) => {},
  setTimeStamp: (timeStamp: number) => {},
  setIsPlaying: (isPlaying: boolean) => {},
})

export const useRecording = () => useContext(RecordingContext)

export const RecordingProvider = ({ children }: { children: ReactNode }) => {
  const [events, setEvents] = useState<IncidentLogEvent[]>([])
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [timeStamp, setTimeStamp] = useState<number>(0)

  const clearRecording = () => {
    setEvents([])
    setAudioSrc(undefined)
    setIsPlaying(false)
    setTimeStamp(0)
  }

  const setRecording = (audioSrc: string) => {
    setEvents([])
    setAudioSrc(audioSrc)
  }

  return (
    <RecordingContext.Provider
      value={{
        events,
        audioSrc,
        isPlaying,
        timeStamp,
        clearRecording,
        setRecording,
        setTimeStamp,
        setIsPlaying,
      }}
    >
      {children}
    </RecordingContext.Provider>
  )
}
