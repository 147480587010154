import { Box, ColorName, Icon, Stack, Text } from "components"
import React from "react"

interface AlarmTypeProps {
  /** Priority of the alarm */
  priority: AlarmPriority
  /** Type of the alarm */
  alarmType?: string
  /** Toggles the color of the closed incident */
  isClosed?: boolean
}

interface AlarmTypeStyleInterface {
  background: ColorName
  text: ColorName
  border: ColorName
}

type StyledAlarmPriority = 1 | 2 | 3 | 4 | 5
export type AlarmPriority = 0 | 1 | 2 | 3 | 4

/**
 * A component that renders a timeline event
 */
export const AlarmType = ({ priority, alarmType, isClosed }: AlarmTypeProps) => {
  const alarmPriorityStyle: Record<StyledAlarmPriority, AlarmTypeStyleInterface> = {
    1: {
      background: "coral",
      text: "white",
      border: "coral",
    },
    2: {
      background: "orange",
      text: "white",
      border: "orange",
    },
    3: {
      background: "aliceBlue3",
      text: "battleShipBlue",
      border: "aliceBlue3",
    },
    4: {
      background: "aliceBlue3",
      text: "battleShipBlue",
      border: "aliceBlue3",
    },
    5: {
      background: "aliceBlue3",
      text: "battleShipBlue",
      border: "aliceBlue3",
    },
  }
  const style = alarmPriorityStyle[(priority + 1) as StyledAlarmPriority]
  return (
    <Box
      padding="2px 11px"
      height="19px"
      background={style?.background}
      borderColor={style?.border}
      borderWidth="1px"
      radius="2px"
      justifyContent="center"
      opacity={isClosed}
    >
      <Stack spacing="5px">
        <Icon name="notifications" size="10px" color={style?.text} />
        <Text color={style?.text} variant="uppercase" bold>
          {alarmType}
        </Text>
      </Stack>
    </Box>
  )
}
