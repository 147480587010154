import Cookies from "js-cookie"

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { _env_: any; }
}

export const envVars = () => {
  if(process.env.NODE_ENV === "production"){
    return {
      API_BASE_URL:
      Cookies.get("AlarmResponseCenterServiceUrl") || window._env_.REACT_APP_API_BASE_URL ||  "",
    SR_BASE_URL: Cookies.get("AlarmResponseCenterServiceUrl")
      ? Cookies.get("AlarmResponseCenterServiceUrl") + "/hub"
      : window._env_.REACT_APP_SR_BASE_URL ?? "",
    SIP_TARGET: window._env_.REACT_APP_SIP_TARGET ?? "",
    SIP_URI: window._env_.REACT_APP_SIP_URI ?? "",
    SIP_PASSWORD: window._env_.REACT_APP_SIP_PASSWORD ?? "",
    IS_DEBUG_MODE: window._env_.REACT_APP_IS_DEBUG_MODE ?? "",
    SIP_WS_URL: window._env_.REACT_APP_SIP_WS_URL ?? "",
    TES_WEB_LOGIN_URL: window._env_.REACT_APP_TES_WEB_LOGIN_URL ?? "",
    REACT_APP_STATIC_MAP: window._env_.REACT_APP_STATIC_MAP ?? "",
    TESMOCKUP_URL: window._env_.REACT_APP_TESMOCKUP_URL ?? "",
    }
  }else{
    return {
      API_BASE_URL:
      Cookies.get("AlarmResponseCenterServiceUrl") || process.env.REACT_APP_API_BASE_URL || "",
    SR_BASE_URL: Cookies.get("AlarmResponseCenterServiceUrl")
      ? Cookies.get("AlarmResponseCenterServiceUrl") + "/hub"
      : process.env.REACT_APP_SR_BASE_URL || "",
    SIP_TARGET: process.env.REACT_APP_SIP_TARGET ?? "",
    SIP_URI: process.env.REACT_APP_SIP_URI ?? "",
    SIP_PASSWORD: process.env.REACT_APP_SIP_PASSWORD ?? "",
    IS_DEBUG_MODE: process.env.REACT_APP_IS_DEBUG_MODE ?? "",
    SIP_WS_URL: process.env.REACT_APP_SIP_WS_URL ?? "",
    TES_WEB_LOGIN_URL: process.env.REACT_APP_TES_WEB_LOGIN_URL ?? "",
    REACT_APP_STATIC_MAP: process.env.REACT_APP_STATIC_MAP ?? "",
    TESMOCKUP_URL: process.env.REACT_APP_TESMOCKUP_URL ?? "",
    }
  }
}
