import { toInteger } from "lodash"

/**
 * return age in years from ssn (social security number)
 * ssn in this format '19431009-7777'
 * @param ssn
 * @returns boolean
 */
export const ageFromSsn = (ssn: string | undefined): number => {
  let age = 0
  if (ssn !== undefined) {
    const birthDate = ssnToDate(ssn)
    const today: Date = new Date()
    age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1
    }
  }
  return age
}

/**
 * returns a date from a ssn in this format '19431009-7777'
 * @returns
 */
export const ssnToDate = (ssn: string): Date => {
  const year = toInteger(ssn.substring(0, 4))
  const month = toInteger(ssn.substring(4, 6)) - 1 // january is month 0
  const day = toInteger(ssn.substring(6, 8))
  const hour = 12
  const date: Date = new Date(year, month, day, hour)
  return date
}

export const isBirthdayToday = (ssn: string) =>
  ssnToDate(ssn).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
