import { addNote, useEvents } from "common"
import { Box, Button, Stack, TextArea } from "components"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface AddNoteProps {
  /** The incident to display */
  incidentId: string
}

/**
 * This component adds a note to the incident.
 */
export const AddNote = ({ incidentId }: AddNoteProps) => {
  const { t } = useTranslation()
  const [note, setNote] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: refreshEvents } = useEvents(incidentId)

  const sendNote = async () => {
    setIsLoading(true)
    note.trim() !== "" && (await addNote(incidentId, note))
    refresh()
    setNote("")
  }

  const refresh = async () => {
    setIsLoading(false)
    refreshEvents()
  }

  /**
   * This component adds a note to the incident.
   */
  return (
    <Stack fullWidth justifyContent="center">
      <Box vertical width="80%" height="150px">
        <Stack vertical fullHeight fullWidth spacing="36px" alignItems="end">
          <TextArea
            focusHotkeyPattern="ctrl+g"
            fullHeight
            fullWidth
            id="AddNoteTextArea"
            value={note}
            onChange={(value) => setNote(value)}
            label={t("General.addANote", "Add a note")}
          ></TextArea>
          <Button
            disabled={isLoading}
            variant="secondary"
            id="addNoteCardBtn"
            hotkey="ctrl+y"
            onClick={sendNote}
          >
            {t("General.addNote", "Add note")}
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}
