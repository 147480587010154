import { SLAResponse } from "common"
import { Box, Stack } from "components/layout"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ProgressBar } from "components"
import { formatSeconds } from "./Time"
import { Text } from "../../typography/Text"

type SLATimerProps = {
  /** The SLA object*/
  sla: SLAResponse
  /** Id for the playwright test*/
  id?: string
}

/**
 * Renders a time which is updated every second.
 * The time is formatted depending on the SLA.
 * If the SLA is broken, the time is red.
 */
export const SLATimer = ({ sla, id }: SLATimerProps) => {
  const startTime = useMemo(() => new Date(sla.startTime), [sla])
  const nowMs = new Date().getTime()
  const calculateIsBroken = () => {
    if (sla.leadTimeMs && sla.targetTimeMs) {
      const finishedAt = startTime.getTime() + sla.leadTimeMs
      const supposedToFinishAt = startTime.getTime() + sla.targetTimeMs
      return supposedToFinishAt < finishedAt
    } else if (sla.targetTimeMs) {
      return startTime.getTime() + sla.targetTimeMs < nowMs
    }
  }
  const isBroken = calculateIsBroken()
  const calculateProgressBarValue = useCallback(() => {
    if (isBroken || sla.leadTimeMs || !sla.targetTimeMs) {
      return 0
    }
    const currentLeadTimeMs = nowMs - startTime.getTime()

    return (currentLeadTimeMs / sla.targetTimeMs) * 100
  }, [isBroken, sla.leadTimeMs, sla.targetTimeMs, nowMs, startTime])

  const [progressBarValue, setProgressBarValue] = useState<number>(calculateProgressBarValue())
  const [difference, setDifference] = useState<number>(
    sla.leadTimeMs ? sla.leadTimeMs / 1000 : (Date.now() - startTime.getTime()) / 1000
  )

  const showProgressbar = !isBroken && !sla.leadTimeMs && progressBarValue > 0

  useEffect(() => {
    if (sla?.leadTimeMs) {
      setDifference((sla.leadTimeMs as number) / 1000)
      return
    }

    const interval = setInterval(() => {
      setDifference((Date.now() - startTime.getTime()) / 1000)
      setProgressBarValue(calculateProgressBarValue())
    }, 1000)
    return () => clearInterval(interval)
  }, [difference, sla?.leadTimeMs, startTime, sla.targetTimeMs, calculateProgressBarValue])

  return (
    <>
      <Box
        id={id}
        background={isBroken ? "twilightRed" : sla.leadTimeMs ? "pureGreyLight" : "aliceBlue3"}
        padding={showProgressbar ? "4px 10px" : "7px 10px"}
        radius="4px"
        justifyContent="center"
        alignItems="center"
        borderWidth="1px"
        borderColor={isBroken && !sla.leadTimeMs ? "coral" : "transparent"}
      >
        <Stack vertical spacing={showProgressbar ? "1px" : "3px"}>
          <Text
            color={isBroken ? "coral" : sla.leadTimeMs ? "pureGreyDark" : "battleShipBlue"}
            variant="h5"
            bold
          >
            {formatSeconds(difference)}
          </Text>
          {showProgressbar && (
            <ProgressBar
              value={progressBarValue}
              color={progressBarValue >= 75 ? "coral" : "greyT5"}
              fullWidth
              background="blackTransparent"
            />
          )}
        </Stack>
      </Box>
    </>
  )
}
