import { Stack } from "components/layout"
import { ReactNode } from "react"
import { ColorName } from "../colors"
import { IconName } from "../icons"
import { Button } from "./Button"
import { Icon } from "./Icon"

interface IconButtonProps {
  /** What to do when the user clicks the icon */
  onClick(): void
  /** The color of the icon */
  color?: ColorName
  /** The name of the icon to show (see `<Icon>`) */
  name: IconName
  /** The size of the icon */
  size?: string
  /** Tab order of the element*/
  tabIndex?: number
  /** Anything shown after the icon, inside the button */
  children?: ReactNode
  /** Pattern for hotkey trigger */
  hotkey?: string
  /** Add an ID to the button */
  id?: string
  /** Disable the button */
  disabled?: boolean
  /** Removes outline on focus*/
  noOutline?: boolean
}

/**
 * A clickable icon!
 */
export const IconButton = ({
  name,
  color,
  onClick,
  size,
  tabIndex,
  children,
  hotkey,
  id,
  disabled,
  noOutline,
}: IconButtonProps) => (
  <Button
    noOutline={noOutline}
    disabled={disabled}
    id={id}
    hotkey={hotkey}
    tabIndex={tabIndex}
    onClick={onClick}
    variant="icon"
  >
    <Stack spacing="0px" alignItems="center">
      <Icon size={size} color={color} name={name}></Icon>
      {children}
    </Stack>
  </Button>
)
