import { ReactNode } from "react"

/**
 * Return the difference in days between the
 * supplied date and now. Negative numbers mean
 * that the supplied date happened in the past.
 */
const daysFromNow = (date: Date) => {
  const comparedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const currentDate = new Date()
  const now = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  const difference = comparedDate.getTime() - now.getTime()

  return Math.round(difference / (1000 * 3600 * 24))
}

interface IDateTimeLocaleProps {
  children: ReactNode | Date
  /**
   * If the date is today or yesterday
   * this will be printed instead of the full date
   */
  relative?: boolean
  /**
   * What locale to format the date in
   * Defaults to sv-SE
   */
  locale?: string
  /**
   * Show the time (defaults to true)
   */
  showTime?: boolean
  /**
   * Show the date (defaults to true)
   */
  showDate?: boolean
  /**
   * When showing time, also show seconds
   * (defaults to only showing hours and minutes)
   */
  showSeconds?: boolean
}

/**
 * Take a time string and display it
 * in a human-friendly manner based on the supplied locale
 */
export const LocalizedDate = ({
  children,
  relative,
  locale = "sv-SE",
  showTime = true,
  showDate = true,
  showSeconds = false,
}: IDateTimeLocaleProps) => {
  if (!children) {
    return null
  }

  const date = new Date(children.toString())
  const daysSince = daysFromNow(date)

  if (relative && daysSince > -2) {
    return (
      <>
        {new Intl.RelativeTimeFormat(locale, {
          numeric: "auto",
        })
          .format(daysSince, "days") // This is for swedish locale to "I dag" will be "Idag"
          .replaceAll(" ", "")}
      </>
    )
  }

  return (
    <>
      {new Intl.DateTimeFormat(locale, {
        dateStyle: showDate ? "short" : undefined,
        timeStyle: showTime ? (showSeconds ? "medium" : "short") : undefined,
      }).format(date)}
    </>
  )
}
