import { css } from "@emotion/css"
import { Box, Button, Stack, TextInput } from "components"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ReasonSelector } from "./common"

interface HandleReasonProps {
  /** The function that executes when a reason and note is posted  */
  callback: (reasonId: string, note?: string) => void
  /** Disables the button */
  isDisabled: boolean
}
/**
 * This component sets a reason and note for an incident.
 */
export const HandleReason = ({ callback, isDisabled }: HandleReasonProps) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState("")
  const [message, setMessage] = useState("")

  const postReasonAndNote = () => {
    if (!reason) return
    callback(reason, message)
    setReason("")
    setMessage("")
  }

  const handleSubmit = () => {
    postReasonAndNote()
  }

  return (
    <form
      className={css`
        display: flex;
      `}
      onSubmit={handleSubmit}
    >
      <Stack fullWidth justifyContent="center" alignItems="end" spacing="28px">
        <Box width="259px">
          <ReasonSelector value={reason} autoFocus onChange={setReason} id="ReasonSelect" />
        </Box>

        <Box width="259px">
          <TextInput
            focusHotkeyPattern="ctrl+e"
            id="createWorkOrderMessageInput"
            label={t("incident.note", "Note")}
            value={message}
            onChange={(value) => setMessage(value)}
            fullWidth
          />
        </Box>

        <Button
          onClick={() => handleSubmit()}
          type="submit"
          id="addReasonButton"
          disabled={isDisabled}
          hotkey="ctrl+u"
          variant="primary"
        >
          {t("incident.save", "Save")}
        </Button>
      </Stack>
    </form>
  )
}
