import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import "./i18n"
import "./index.css"
import { reportWebVitals } from "./reportWebVitals"

const container = document.getElementById("app")

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
reportWebVitals()
