import { useCurrentAccount, usePersonnel } from "common"
import { Personnel } from "generated/api"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"

const defaultOperator: Personnel = {
  personnelId: "",
  displayName: "",
  details: { lastName: "", firstName: "", contact: { phone: "", mobilePhone: "", homePhone: "" } },
}

const OperatorContext = createContext(defaultOperator)

export const useOperator = () => useContext(OperatorContext)

/**
 * Providing application with the current logged in operator.
 */
export const OperatorProvider = ({ children }: { children: ReactNode }) => {
  const { data: loggedInOperator } = useCurrentAccount()
  const { data: personnel } = usePersonnel(loggedInOperator?.personnelId || "")
  const [operator, setOperator] = useState(defaultOperator)

  useEffect(() => {
    personnel && setOperator(personnel)
  }, [personnel])

  return <OperatorContext.Provider value={operator}>{children}</OperatorContext.Provider>
}
