import { css } from "@emotion/css"
import { Stack } from "../layout/Stack"
import React from "react"
import ReactDOM from "react-dom"
import { IconButton } from "../ui/IconButton"
import { Text } from "../typography/Text"

interface ToastProps {
  /** The message to show */
  children: string
  onClose(): void
}

const toastStyle = css`
  position: fixed;
  top: 30px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
`

/**
 * A simple toast message that pops up in the top right corner
 */
export const Toast = ({ onClose, children }: ToastProps) => {
  return ReactDOM.createPortal(
    <div className={toastStyle}>
      <Stack alignItems="center">
        <Text bold color="white">
          {children}
        </Text>
        <IconButton name="cancel" onClick={onClose} color="white" />
      </Stack>
    </div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("toastPortal")!
  )
}
