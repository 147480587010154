import { IncidentEvent, PersonBadge, SLAResponse, useRecording } from "common"
import { Box, Button, ColorName, Icon, LocalizedDate, SLATimer, Stack, Text } from "components"
import { IconName } from "components/icons"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

interface LogItemProps {
  /** The name of the event / state */
  title?: ReactNode
  children?: ReactNode
  /** Name of event creator */
  createdBy?: string
  /** Decides which user badge to display  */
  userRole?: "staff" | "operator"
  /** The time when the event was happening */
  timeStamp?: string
  /** SLA object */
  sla?: SLAResponse | undefined
  /** The first event/state in the timeline */
  start?: boolean

  end?: boolean
  /** Is happening within a call */
  hasRecording?: boolean | string
  /** Sets the recorded call */
  setRecording?: (isPLaying: boolean, skipTo?: string) => void
  /** The type of the event */
  eventType?: IncidentEvent
  isIncidentList?: boolean
}

/** Events to be displayed in the timeline */
type LogItemEvents =
  | "paged"
  | "callConnected"
  | "callEnded"
  | "accepted"
  | "presence"
  | "reasonSet"
  | "presenceDone"
  | "closedWithoutAction"

const iconStyle: Record<
  LogItemEvents,
  { name: IconName; color: ColorName; borderStyle: "dashed" | "solid" }
> = {
  paged: {
    name: "swap_vert",
    color: "mustard",
    borderStyle: "solid",
  },

  callConnected: {
    name: "call",
    color: "greyT10",
    borderStyle: "dashed",
  },
  callEnded: {
    name: "call_end",
    color: "greyT10",
    borderStyle: "dashed",
  },
  accepted: {
    name: "done",
    color: "greenLight",
    borderStyle: "dashed",
  },
  presence: {
    name: "location_pin",
    color: "lightPurple",
    borderStyle: "dashed",
  },
  presenceDone: {
    name: "done",
    color: "white",
    borderStyle: "solid",
  },
  closedWithoutAction: {
    name: "verified",
    color: "green",
    borderStyle: "solid",
  },
  reasonSet: {
    name: "question_mark",
    color: "purple",
    borderStyle: "solid",
  },
}

export const LogItem = ({
  title,
  children,
  createdBy,
  userRole,
  timeStamp,
  sla,
  start,
  end,
  hasRecording,
  setRecording,
  eventType,
  isIncidentList,
}: LogItemProps) => {
  const { t } = useTranslation()
  const recording = useRecording()

  const style = eventType && iconStyle[eventType as LogItemEvents]

  return (
    <Box
      justifyContent="space-between"
      position="relative"
      moveForward
      width={isIncidentList ? undefined : "100%"}
      padding={isIncidentList ? "0" : "0px 0px 16px 0px"}
    >
      <Stack spacing="21px" alignItems={end ? "start" : "center"} fullWidth>
        <Box background="white" width="70px" vertical alignItems="center">
          <Box borderWidth="1px" borderColor="greyT20" radius="2px" padding="2px 5px">
            <Text variant="h5" color="grey">
              {timeStamp ? (
                <LocalizedDate showDate={false}>{timeStamp}</LocalizedDate>
              ) : (
                t("timeLineEvent.Now", "Now")
              )}
            </Text>
          </Box>
        </Box>
        <Stack spacing="10px" alignItems="center">
          {!start && style && eventType !== "noteAdded" && (
            <Box
              radius="50%"
              background={eventType === "presenceDone" ? "green" : "white"}
              borderStyle={style.borderStyle}
              padding="4px"
              borderColor={style.color}
              borderWidth="1px"
            >
              <Icon color={style.color} size="18px" name={style.name} />
            </Box>
          )}
          <Stack vertical spacing="6px">
            {typeof title === "string" ? <Text bold>{title}</Text> : title}
            {children}
            <PersonBadge role={userRole} name={createdBy} />
          </Stack>
        </Stack>
      </Stack>

      <Box width="95px" alignItems="center" justifyContent="center">
        {eventType === "callEnded" && hasRecording && (
          <>
            {setRecording && (
              <Button
                noOutline
                onClick={() => setRecording(recording.isPlaying ? false : true)}
                variant="text"
              >
                <Icon
                  size="29px"
                  color="azure"
                  name={recording.isPlaying ? "pause_circle_filled" : "play_circle_filled"}
                />
              </Button>
            )}
          </>
        )}
      </Box>
      <Box width="95px" alignItems="center" justifyContent="end">
        {sla && <SLATimer id={`eventSLA${sla.type}`} sla={sla} />}
      </Box>
    </Box>
  )
}
