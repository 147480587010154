import { useNavigation, usePersistentState, useQueue, useShowHotkeysHelp } from "common"
import { Box, Button, Divider, Hotkey, Icon, IconButton, Stack, Text, Time } from "components"
import { useDebug } from "features/debug"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Queue } from "./common"

/**
 * This component renders the Sidebar, which contains the Queue.
 */
export const SideBar = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const debug = useDebug()
  const { incidentList, averageResponseTime } = useQueue()
  const { toggleShowHelp, showHelp } = useShowHotkeysHelp()
  const [isOpen, setIsOpen] = usePersistentState<boolean>("queueIsOpen", true)

  useEffect(() => {
    debug.setIncidentList(incidentList)
  }, [debug, incidentList])

  return (
    <Box
      alignItems="stretch"
      vertical
      width={isOpen ? "300px" : "85px"}
      minWidth={isOpen ? "300px" : "85px"}
      background="aliceBlue"
      id="queueHeader"
      borderWidth="0 1px 0 0"
      borderColor="greyT15"
      justifyContent="space-between"
      transition
    >
      {isOpen ? (
        <Box justifyContent="space-between" padding="20px 30px 40px 30px" width="100%">
          <Stack fullWidth spacing="21px" alignItems="center">
            <IconButton
              id="HomeButton"
              tabIndex={-1}
              name="home"
              size="17px"
              color="azure"
              onClick={navigation.goToStart}
              hotkey="F1"
            />
            <Stack vertical>
              <Text variant="h1">{t("common.incoming", "Incoming")}</Text>
              <Text id="NumQueueIncidents" light>
                {incidentList ? incidentList.length : 0} {t("common.inQueue", "in queue")}
              </Text>
            </Stack>
          </Stack>
          <Time seconds={averageResponseTime} />
        </Box>
      ) : (
        <Box justifyContent="center" alignItems="center" padding="23px 0 30px 0">
          <Stack fullWidth vertical alignItems="center" spacing="25px">
            <IconButton
              id="HomeButton"
              tabIndex={-1}
              name="home"
              size="17px"
              color="azure"
              onClick={navigation.goToStart}
              hotkey="F1"
            />
            <Text variant="h2">{incidentList.length}</Text>
            <Time seconds={averageResponseTime} />
            <Divider />
          </Stack>
        </Box>
      )}
      <Queue incidentList={incidentList} isOpen={isOpen} />
      <Box vertical>
        <Stack alignItems="center" padding="40px 15px 15px 15px" spacing="5px">
          {isOpen && (
            <Text variant="h3" color="greyT10">
              {t("common.press", "Press")}
            </Text>
          )}
          <Hotkey
            color={"azure"}
            pattern="ctrl+h"
            alwaysVisible={isOpen}
            callback={toggleShowHelp}
          />
          {isOpen && (
            <Text variant="h3" color="greyT10">
              {t("common.toShowHotkeys", "to show hotkeys.")}
            </Text>
          )}
        </Stack>

        <Divider />

        <Box
          padding={isOpen ? "15px" : "0"}
          width="100%"
          height="65px"
          alignItems="center"
          justifyContent="center"
        >
          <Button hotkey="F2" tabIndex={-1} onClick={() => setIsOpen(!isOpen)} variant="text">
            {isOpen ? (
              <Stack justifyContent="center" alignItems="center" spacing={isOpen ? "10px" : "0px"}>
                <Stack alignItems="center">
                  <Icon color="azure" size="16px" name="chevron_left" />
                  <Text bold color="azure">
                    {t("common.hideIncoming", "Hide incoming")}
                  </Text>
                </Stack>
              </Stack>
            ) : (
              !showHelp && <Icon color="azure" size="40px" name="chevron_right" />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
