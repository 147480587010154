/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactDOM from "react-dom"
import { Card } from "./Card"
import { css } from "@emotion/css"
import { ReactNode, useEffect, useRef, MouseEvent } from "react"
import { useHotkeys } from "common"

interface ModalProps {
  /** The contents of the modal card */
  children: ReactNode
  /** ID for the modal backdrop */
  id?: string
  /** The modal card header. Accepts a string or component  */
  header: ReactNode
  /** Function to call when closing the modal */
  onClose: () => void
  /** Width of the modal card */
  width?: string
}

const modalStyle = css`
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 1;
`

/**
 * A Card that renders on top of the application.
 */
export const Modal = ({ children, id, header, onClose, width }: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useHotkeys("esc", onClose)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  const handleClose = (e: MouseEvent) => {
    if (!ref.current) return
    if (!e.nativeEvent.composedPath().includes(ref.current)) {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div onClick={handleClose} id={id} className={modalStyle}>
      <div ref={ref}>
        <Card width={width} header={header}>
          {children}
        </Card>
      </div>
    </div>,
    document.getElementById("modalPortal")!
  )
}
