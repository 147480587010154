import { formatSeconds } from "./formatSeconds"

interface PrettyTimeProps {
  /** A length of time in seconds */
  children?: number
  /** Show the time units (hours and minutes) */
  showUnits?: boolean
}

/**
 * A component that converts seconds into a human-readable time length (so that 138 seconds becomes `02:18`).
 * It wraps the `formatSeconds` function.
 *
 * @param {number} seconds the number of seconds to convert
 * @param {boolean} showUnits - Whether to show units like 1h2m3s, otherwise 01:02:03
 * @returns Formatted seconds as a string (see formatSeconds() for details)
 */
export const PrettyTime = ({ children, showUnits = true }: PrettyTimeProps) => (
  <>{children !== undefined && formatSeconds(children, showUnits ? "hms" : ":")}</>
)
