import { css } from "@emotion/css"
import { Hotkey } from "components"
import { useRef } from "react"
import { Colors } from "../colors"
import { Stack } from "../layout/Stack"
import { Text } from "../typography/Text"

interface TextInputProps {
  /** The ID used by the Label and any test framework used */
  id?: string
  /** The label to show above the input */
  label: string
  /** The value to be displayed in the input */
  value?: string
  /** A callback that will be called when the user inputs something (it is debounced) */
  onChange(val: string): void
  /** Focuses the input element */
  autoFocus?: boolean
  /** FullWidth */
  fullWidth?: boolean
  /** Hotkey pattern */
  focusHotkeyPattern?: string
}

const inputStyle = css`
  padding: 7px 9px;
  border: 1px solid ${Colors.greyT10};
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: ${Colors.greyT5};
  padding-right: 27px;
  width: 100%;
  height: 100%;
`

/**
 * A simple text input component
 */
export const TextInput = ({
  id,
  label,
  value,
  onChange,
  autoFocus,
  fullWidth,
  focusHotkeyPattern,
}: TextInputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <Stack fullWidth={fullWidth} vertical spacing="3px">
      <label htmlFor={id}>
        <Stack spacing="8px" alignItems="end">
          <Text color="grey" variant="h5">
            {label}
          </Text>
          {focusHotkeyPattern && (
            <Hotkey
              pattern={focusHotkeyPattern}
              callback={() => ref.current?.focus()}
              color="azure"
            />
          )}
        </Stack>
      </label>
      <input
        ref={ref}
        id={id}
        autoFocus={autoFocus}
        data-playwrighttag={id}
        type="text"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        className={inputStyle}
      />
    </Stack>
  )
}
