import { usePersistentState } from "common"
import { Box, Stack, Tab } from "components"
import { CreateRemoteVisitIncident, IncidentList } from "features/incidents"
import { useTranslation } from "react-i18next"

/**
 * Display Homepage
 *
 */
export const HomePage = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = usePersistentState<string>("currentTab", "Active")

  return (
    <Box id="HomePage" width="100%" height="100%" alignItems="center" justifyContent="center">
      <Box maxWidth="1200px" width="100%" maxHeight="100%" hideOverflow>
        <Stack vertical spacing="24px" fullWidth padding="24px">
          <Stack justifyContent="space-between" fullWidth>
            <Stack>
              <Tab
                id="ActiveIncidentTab"
                title="Active"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                hotkey="F6"
              >
                {t("homepage.activeIncidents", "Active incidents")}
              </Tab>
              <Tab
                id="ClosedIncidentTab"
                title="Closed"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                hotkey="F7"
              >
                {t("homepage.closedIncidents", "Closed incidents")}
              </Tab>
            </Stack>

            <CreateRemoteVisitIncident />
          </Stack>

          <Stack spacing="24px" fullWidth alignItems="start">
            {activeTab === "Active" ? (
              <>
                <IncidentList
                  id="WaitingIncidents"
                  icon="alarm"
                  title={`${t("home.waiting", "Waiting")}`}
                  incidentType="Waiting"
                  focusHotkeyPattern="left"
                />
                <IncidentList
                  id="ActiveIncidents"
                  icon="timelapse"
                  title={`${t("home.active", "Active")}`}
                  incidentType="Active"
                  focusHotkeyPattern="right"
                />
              </>
            ) : (
              <Box width="50%">
                <IncidentList
                  id="ClosedIncidents"
                  icon="check_circle"
                  title={`${t("home.closed", "Closed")}`}
                  incidentType="Closed"
                  focusHotkeyPattern="left"
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
