import { Text } from "../../typography/Text"

interface TableHeaderProps {
  title?: string
}
export const TableHeader = ({ title }: TableHeaderProps) => (
  <th>
    <Text bold color="greyT5" upperCase>
      {title}
    </Text>
  </th>
)
