import {
  CallStatus,
  GetCareRecipient,
  nextIncident,
  useCall,
  useIncident,
  useLoading,
  useNavigation,
  useQueue,
  useRecording,
  useUnload,
} from "common"
import {
  Box,
  Button,
  Clickable,
  Confirm,
  Heading,
  Icon,
  ProgressBar,
  Stack,
  Text,
} from "components"
import { AudioPlaybackBar } from "features"
import { useState } from "react"
import { useTranslation } from "react-i18next"

/**
 * This component renders the call bar which is located at the top of the pag≠e and handle the logic for the call.
 */
export const CallBar = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const call = useCall()
  const queue = useQueue()
  const { isLoading } = useLoading()
  const recording = useRecording()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const statusMap: Record<CallStatus, string> = {
    started: t("call.disconnect", "Hang up"),
    ended: t("call.next", "Next incident"),
    failed: t("call.failed", "Call failed"),
    pending: t("call.calling", "Calling"),
    notStarted: t("call.next", "Next incident"),
  }
  /**
   * Show warning when user tries to reload or navigate away from the call
   */
  useUnload((e: { preventDefault: () => void; returnValue: string }) => {
    if (call.status === "started" || call.status === "pending") {
      e.preventDefault()
      e.returnValue = ""
    }
    return
  })

  const goToNextIncident = async () => {
    const incident = await nextIncident()

    if (incident) {
      call.connect(incident)
      navigation.goToIncident(incident.careRecipients[0].id, incident.id)
    }
  }

  const { data: incident } = useIncident(call.incident?.id)
  const callIncidentIsShown = incident && navigation.isIncident(incident?.id)
  const callIncidentIsNotShown =
    incident && call.status === "started" && !navigation.isIncident(incident?.id)

  return (
    <Stack vertical fullWidth>
      {!recording.audioSrc ? (
        <Box
          height="50px"
          alignItems="center"
          borderWidth="0 0 1px 0"
          borderColor={callIncidentIsShown ? "pureGreyLightest" : "greyT15"}
          width="100%"
          background={callIncidentIsShown ? "pureGreyLightest" : "aliceBlue"}
          padding="27px"
          id="CallBar"
          pulse={callIncidentIsNotShown}
        >
          <Stack
            height="50px"
            spacing="12px"
            fullWidth
            alignItems="center"
            justifyContent="flex-end"
          >
            <Clickable
              fullWidth
              fullHeight
              tabIndex={-1}
              onClick={
                incident
                  ? () => navigation.goToIncident(incident.careRecipients[0].id, incident.id)
                  : undefined
              }
            >
              {call.status === "started" && incident ? (
                <GetCareRecipient careRecipientId={incident.careRecipients[0].id}>
                  {({ data: careRecipient, loading }) =>
                    loading ? (
                      <ProgressBar />
                    ) : (
                      careRecipient && (
                        <Box grow justifyContent="space-between">
                          <Stack fullWidth spacing="12px" alignItems="center">
                            <Icon name="phone" color="coral" size="20px" />
                            <Heading color="coral" level="h3" id="FullName">
                              {careRecipient?.name}
                            </Heading>
                          </Stack>
                          <Stack fullWidth spacing="6px" alignItems="center">
                            <Text
                              id="callBarTitle"
                              variant={callIncidentIsShown ? "h1" : "h3"}
                              color="coral"
                            >
                              {incident.reasonDescription != null
                                ? incident.reasonDescription
                                : incident.typeDescription}
                            </Text>
                          </Stack>
                        </Box>
                      )
                    )
                  }
                </GetCareRecipient>
              ) : (
                incident && (
                  <Box grow justifyContent="space-between">
                    <Stack fullWidth spacing="12px" alignItems="center">
                      <Icon name="call_end" color="greyT10" size="20px" />
                      <Heading color="greyT10" level="h3" id="FullName">
                        {incident.careRecipients[0].name}
                      </Heading>
                    </Stack>
                    <Stack fullWidth alignItems="center">
                      <Text variant="h3" color="greyT10">
                        {incident.reasonDescription != null
                          ? incident.reasonDescription
                          : incident.typeDescription}
                      </Text>
                    </Stack>
                  </Box>
                )
              )}
            </Clickable>

            {callIncidentIsNotShown && (
              <Button
                id="GoToIncident"
                hotkey="F3"
                variant="primary"
                tabIndex={-1}
                onClick={() => navigation.goToIncident(incident.careRecipients[0].id, incident.id)}
              >
                <Text bold color="white">
                  {t("incident.goToIncident", "Go to incident")}
                </Text>
              </Button>
            )}

            {call.status === "started" ? (
              <Button
                id="HangUpButton"
                hotkey="F4"
                tabIndex={-1}
                onClick={() => setIsConfirmOpen(true)}
                variant="important"
              >
                <Text bold color="white">
                  {statusMap[call.status]}
                </Text>
              </Button>
            ) : (
              <Button
                id="NextIncidentButton"
                disabled={
                  queue.incidentList.length === 0 ||
                  call.status === "pending" ||
                  incident?.state === "handledByOperator"
                }
                hotkey="F3"
                tabIndex={-1}
                onClick={goToNextIncident}
                variant="primary"
              >
                <Text bold color="white">
                  {statusMap[call.status]}
                </Text>
              </Button>
            )}
          </Stack>
        </Box>
      ) : (
        <AudioPlaybackBar />
      )}
      <Box background={"pureGreyLightest"} width="100%" height="5px" alignItems="start">
        {isLoading && <ProgressBar noRadius fullWidth />}
      </Box>
      {isConfirmOpen && (
        <Confirm
          id="HangUpConfirm"
          title={t("confirm.HangUp", "Are you sure you want to hang up?")}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={call.disconnect}
        ></Confirm>
      )}
    </Stack>
  )
}
