import { css, cx } from "@emotion/css"
import { useIsScrolling } from "../../hooks/useIsScrolling"
import { ReactNode, useRef } from "react"
import { Box } from "../Box"

interface ITableProps {
  height?: string
  playwrightTag?: string
  children: ReactNode
}

const tableStyle = css`
  border-collapse: separate;
  border-spacing: 0;
  flex-grow: 1;
`

const noEventsStyle = css`
  pointer-events: none;
`

export const Table = ({ children, height, playwrightTag }: ITableProps) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const isScrolling = useIsScrolling(boxRef)

  return (
    <Box
      boxRef={boxRef}
      width="100%"
      minHeight="0"
      height={height}
      scroll="vertical"
      id={playwrightTag}
    >
      <table className={cx(tableStyle, isScrolling && noEventsStyle)}>{children}</table>
    </Box>
  )
}
