import { css } from "@emotion/css"
import { envVars, Incident, IncidentEvent, useCall, useOperator } from "common"
import {
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  SearchSelect,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  TextInput,
  Timer,
} from "components"
import Cookies from "js-cookie"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useDebug } from "./DebugProvider"

const remoteVisitIncidentCode = "99999"

export const DebugInfo = () => {
  const { t } = useTranslation()
  const operator = useOperator()
  const [isOpen, setIsOpen] = useState(false)
  const [isPosting, setIsPosting] = useState(false)
  const [isPostingMany, setIsPostingMany] = useState(false)
  const [isClearingQueue, setIsClearingQueue] = useState(false)
  const { connectionState, connectionId, incidentList } = useDebug()
  const call = useCall()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [accessToken, setAccessToken] = useState<string>()
  const [SIPURI, setSIPURI] = useState<string>(envVars().SIP_URI)
  const [SIPPassword, setSIPPassword] = useState<string>(envVars().SIP_PASSWORD)
  const [alarmsToGenerate, setAlarmsToGenerate] = useState<string>()
  const [selectedEvent, setSelectedEvent] = useState<string>()
  const [updateResponse, setUpdateResponse] = useState<Response>()
  const incidentEvent: IncidentEvent[] = [
    "accepted",
    "presence",
    "presenceDone",
    "paged",
    "callEnded",
    "callConnected",
  ]
  const incidentCode = [
    "11111",
    "22222",
    "77777",
    remoteVisitIncidentCode,
    "33333",
    "55555",
    "1111",
    "187",
    "00000",
    "44444",
    "540",
  ]
  const [selectedIncidentCode, setSelectedIncidentCode] = useState<string>()
  const location = useLocation()
  const [language, setLanguage] = useState<string | null>(localStorage.getItem("i18nextLng"))

  const setLocalStorageLanguage = (lang: string) => {
    setLanguage(lang)

    localStorage.setItem("i18nextLng", lang)
    window.location.reload()
  }

  const setAccessTokenOnClick = () => {
    if (accessToken === undefined) {
      return
    }
    Cookies.set("Bearer", accessToken)

    // Need to reload the page in order to read the token.
    window.location.reload()
  }

  const generateAlarm = async (isManyAlarms = false) => {
    let alarmAmountToGenerate = 1
    if (isManyAlarms) {
      if (!isNaN(Number(alarmsToGenerate))) {
        alarmAmountToGenerate = Number(alarmsToGenerate)
        setIsPostingMany(true)
      } else {
        return
      }
    } else {
      setIsPosting(true)
    }
    let data
    if (selectedIncidentCode !== undefined) {
      if (selectedIncidentCode === remoteVisitIncidentCode) {
        data = {
          alarmCode: selectedIncidentCode,
          responsibleAlarmOperatorGUID: operator.personnelId,
        }
      } else {
        data = { alarmCode: selectedIncidentCode }
      }
      data = JSON.stringify(data)
    }

    for (let i = 0; i < alarmAmountToGenerate; i++) {
      await fetcher("POST", data)
    }

    isManyAlarms ? setIsPostingMany(false) : setIsPosting(false)
  }

  const clearQueue = async (removeJustQueue = false) => {
    setIsClearingQueue(true)
    await fetch(
      `${envVars().TESMOCKUP_URL}?removeJustQueue=${removeJustQueue ? "true" : "false"}`,
      {
        method: "DELETE",
      }
    )
    setIsClearingQueue(false)
  }

  const updateIncident = async () => {
    const incidentId = location.pathname.split("/")[4]
    const res = await fetch(`${envVars().TESMOCKUP_URL}/${incidentId}/${selectedEvent}`, {
      method: "POST",
      body: `'${operator.personnelId}'`,
      headers: {
        "Content-Type": "application/json",
      },
    })
    setUpdateResponse(res)
  }

  const fetcher = async (method: "POST" | "DELETE", data?: string) =>
    await fetch(`${envVars().TESMOCKUP_URL}`, {
      method: method,
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    })

  const debugInfoPanel = css`
    position: fixed;
    bottom: 10px;
    right: 10px;
    max-height: 90%;
    z-index: 1;
    background-color: #fff;
    border: ${isOpen ? "1px solid #ccc" : "none"};
    padding: ${isOpen ? "16px" : "0"};
    box-shadow: "0 1px 10px 1px rgba(0, 0, 0, 0.12)";
    overflow-y: auto;
  `
  const testIncident: Incident = {
    /** @format uuid */
    id: "02f4e89b-507e-42ee-a150-e1b90e05b30f",
    careRecipients: [{id: "2092419d-148b-eb11-9d13-40618690f21b"}],
    alarmCode: "",
    createdTime: Date.now().toString(),
    typeDescription: "",
    sipNumber: "333",
    priority: 1,
    departmentName: "test"
  }

  return isOpen ? (
    <div className={debugInfoPanel}>
      <Stack vertical>
        <Button
          hotkey="ctrl+d"
          tabIndex={-1}
          onClick={() => setIsOpen(!isOpen)}
          variant="text"
          fullWidth
        >
          <Text bold color="azure">
            Hide debug info
          </Text>
        </Button>

        <Text bold>
          {process.env.REACT_APP_BUILDNUMBER &&
            `Build Number: ${process.env.REACT_APP_BUILDNUMBER} | `}
          {process.env.REACT_APP_SOURCEVERSION &&
            `Source Version: ${process.env.REACT_APP_SOURCEVERSION.substring(0, 7)}`}
        </Text>
        <Stack vertical padding="10px 0 20px 0">
          <Heading level="h3">Operator</Heading>
          <Text>Name: {operator?.displayName}</Text>
          <Text>ID: {operator.personnelId}</Text>
        </Stack>
        <Divider />
        <Stack vertical padding="10px 0 10px 0">
          <Heading level="h3">SignalR</Heading>
          <Text>Connection id: {connectionId}</Text>
          <Stack>
            {connectionState === "Connected" ? (
              <Icon name="check" color="azure" size="20px" />
            ) : (
              <Icon name="close" color="coral" size="20px" />
            )}
            <Text>{connectionState}</Text>
          </Stack>
        </Stack>
        <Divider />
        <Stack vertical padding="10px 0 20px 0">
          <Heading level="h3">SIP Call</Heading>
          <Text>Account: {SIPURI}</Text>
          <Stack spacing="4px" alignItems="center">
            <Text>SIP Status:</Text>
            <Icon
              color={call.isConnected ? "azure" : "coral"}
              name={call.isConnected ? "sentiment_very_satisfied" : "sentiment_very_dissatisfied"}
              size="20px"
            />
            <Text>{call.isConnected ? "Connected" : "Disconnected"}</Text>
          </Stack>
          <Stack spacing="4px" alignItems="end" padding="5px 0 0 0">
            <TextInput
              label="URI"
              value={SIPURI}
              onChange={(value) => {
                setSIPURI(value)
              }}
            />
            <TextInput
              label="Password"
              value={SIPPassword}
              onChange={(value) => {
                setSIPPassword(value)
              }}
            />
            <Button
              variant="primary"
              onClick={() => {
                call.login(SIPURI, SIPPassword)
              }}
            >
              Login
            </Button>
          </Stack>
          <Stack alignItems="end" spacing="5px" padding="10px 0 0 0">
            <Text>Call Status: {call.callDevelopmentStatus}</Text>
          </Stack>
          <Stack alignItems="end" spacing="5px" padding="5px 0 0 0">
            <TextInput
              label="Phone number"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(number) => setPhoneNumber(number)}
            />
            <Button
              disabled={phoneNumber.trim() === ""}
              onClick={() => {
                if (phoneNumber.trim() !== "") {
                  testIncident.sipNumber = phoneNumber
                  call.connect(testIncident, true)
                }
              }}
              variant="primary"
            >
              Call
            </Button>
            <Button
              disabled={["ended", "failed", "notStarted"].includes(call.callDevelopmentStatus)}
              onClick={call.disconnect}
              variant="important"
            >
              Hang up
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack vertical padding="10px 0 20px 0">
          <Heading level="h3">Access Token</Heading>
          <Stack alignItems="end" spacing="5px">
            <TextInput
              id="accessToken"
              label="e.g. eyJhbGciOiJBdsGD..."
              onChange={(e) => setAccessToken(e)}
            />
            <Button variant="important" onClick={setAccessTokenOnClick}>
              Set
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack vertical padding="10px 0 20px 0" fullWidth>
          <Heading level="h3">Alarm</Heading>
          <Heading level="h4">Generate:</Heading>
          <Stack alignItems="end" spacing="10px">
            <Box width="30%">
              <SearchSelect
                placeholder="Incident code"
                value={selectedIncidentCode}
                id="fdg"
                label="Code"
                onChange={(e) => setSelectedIncidentCode(e)}
                options={incidentCode.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </Box>
            <Button disabled={isPosting} variant="primary" onClick={() => generateAlarm()}>
              1 alarm
            </Button>
            <TextInput
              id="accessToken"
              label="How many?"
              onChange={(e) => setAlarmsToGenerate(e)}
            />
            <Button
              disabled={isPostingMany || isNaN(Number(alarmsToGenerate)) || !alarmsToGenerate}
              variant="primary"
              onClick={() => generateAlarm(true)}
            >
              Generate
            </Button>

            <Button disabled={isClearingQueue} variant="important" onClick={() => clearQueue(true)}>
              Clear queue
            </Button>
            <Button disabled={isClearingQueue} variant="important" onClick={() => clearQueue()}>
              Clear all
            </Button>
          </Stack>

          <Stack vertical fullWidth padding="24px 0px">
            <Heading level="h4">Update:</Heading>
            <Stack alignItems="end" spacing="5px" fullWidth>
              <Box width="30%">
                <SearchSelect
                  placeholder="Incident event"
                  value={selectedEvent}
                  id="fdg"
                  label="Event"
                  onChange={(e) => setSelectedEvent(e)}
                  options={incidentEvent.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Box>
              <Button disabled={!selectedEvent} variant="primary" onClick={updateIncident}>
                Update
              </Button>
              <Text>Response status code: {updateResponse?.status}</Text>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Box padding="10px 0 20px 0">
          <Button
            onClick={() => setLocalStorageLanguage(language === "sv-SE" ? "en-US" : "sv-SE")}
            variant="primary"
          >
            {t("debug.toggleLanguage", "Toggle language")}
          </Button>
        </Box>
        <Divider />

        <Stack vertical padding="10px 0 0px 0">
          <Heading level="h3">Incident list ({incidentList?.length})</Heading>
          <Box height="300px" width="650px" scroll="vertical">
            <Table>
              <TableHead>
                <TableHeader title="Index" />
                <TableHeader title="Name" />
                <TableHeader title="SLA" />
                <TableHeader title="Department" />
                <TableHeader title="Incident Priority" />
                <TableHeader title="Incident Type" />
              </TableHead>
              <TableBody>
                {incidentList?.map((incident, index) => (
                  <TableRow key={incident.id}>
                    <td>{index + 1}</td>
                    <td>{incident.careRecipients[0].name}</td>
                    <td>
                      <Timer from={incident.createdTime} />
                    </td>
                    <td>{incident.departmentName}</td>
                    <td>{incident.priority}</td>
                    <td>{incident.typeDescription}</td>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </Stack>
    </div>
  ) : (
    <div className={debugInfoPanel}>
      <Button
        hotkey="ctrl+d"
        tabIndex={-1}
        onClick={() => setIsOpen(!isOpen)}
        variant="text"
        fullWidth
      >
        <Text bold color="azure">
          Open debug info
        </Text>
      </Button>
    </div>
  )
}
