import { Stack, Text } from "components"
import { useTranslation } from "react-i18next"

export const HandleIncidentClosed = () => {
  const { t } = useTranslation()
  return (
    <Stack vertical spacing="8px">
      <Text id="HandleIncidentClosed" variant="h2">
        {t("incident.handlingIncidentClosed", "Incident is closed")}
      </Text>
    </Stack>
  )
}
