import { GetReasons } from "common"
import { ProgressBar, SearchSelect } from "components"
import { Dispatch, SetStateAction } from "react"

import { useTranslation } from "react-i18next"

interface ReasonSelectorProps {
  /** Sets focus automatically when render occurs */
  autoFocus?: boolean
  /**
   * Get the newly selected value.
   *
   * Returns the `value` of the selected option object
   */
  onChange: Dispatch<SetStateAction<string>>
  /** Id for playwright test */
  id: string
  /** Selected value */
  value?: string
}

export const ReasonSelector = ({ autoFocus, onChange, id, value }: ReasonSelectorProps) => {
  const { t } = useTranslation()
  return (
    <GetReasons>
      {({ data: options }) =>
        options ? (
          <SearchSelect
            placeholder={t("incident.selectAReason", "Select a reason")}
            value={value}
            focusHotkeyPattern="ctrl+R"
            id={id}
            autoFocus={autoFocus}
            label={t("incident.reason", "Reason")}
            onChange={onChange}
            options={options.map((item, index) => ({
              // TODO: Remove this hack '00' when we have a correct data
              label: `00${index} ${item.description}`,
              value: item.reasonId,
            }))}
          />
        ) : (
          <ProgressBar />
        )
      }
    </GetReasons>
  )
}
