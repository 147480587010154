import { useNavigate, useLocation } from "react-router"

/**
 * All the different base routes in this application
 */
export const routes = {
  incident: "/recipient/:recipientId/incident/:incidentId",
  start: "/",
}

/**
 * A hook that returns function for imperatively
 * navigating the application (ie. switching routes).
 */
export const useNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return {
    goToIncident: (recipientId: string, incidentId: string) =>
      navigate(
        routes.incident.replace(":recipientId", recipientId).replace(":incidentId", incidentId)
      ),
    goToStart: () => navigate(routes.start),
    /**
     * Returns whether the current page is the incident page.
     * @param incidentId (Optional) The incident id to check for. If left blank it will check for any incident id.
     */
    isIncident: (incidentId = "") => location.pathname.includes(incidentId),
  }
}
