import { useIncident } from "common"
import { Box, Stack } from "components"
import { RecipientInfo, TimeLine } from "features"
import { MapCard, RecipientHistory } from "features/recipients"
import { useParams } from "react-router-dom"
import { HandleIncident } from "./HandleIncident"

/**
 * This page shows information about the current call,
 * including the CallBar which handles the actual call functionality
 */
export const IncidentPage = () => {
  const { recipientId, incidentId } = useParams<{ recipientId: string; incidentId: string }>()
  const { data: incident } = useIncident(incidentId, 10000)

  return incidentId && recipientId ? (
    <Box vertical height="100%" grow>
      <Box
        id="IncidentPage"
        scroll="vertical"
        padding="24px"
        width="100%"
        alignItems="stretch"
        grow
      >
        <Stack fullHeight vertical spacing="26px" fullWidth>
          <RecipientInfo recipientId={recipientId} incidentCode={incident?.alarmCode} />
          <Stack spacing="26px" fullWidth>
            <Box width="55%">
              <TimeLine incident={incident} />
            </Box>
            <Box height="100%" alignItems="stretch" width="45%">
              <RecipientHistory recipientId={recipientId} />
            </Box>
          </Stack>
          <Stack spacing="26px" fullWidth>
            <Box padding="0 0 26px 0" width="55%">
              <MapCard recipientId={recipientId} />
            </Box>
            <Box height="100%" alignItems="stretch" width="45%"></Box>
          </Stack>
        </Stack>
      </Box>
      <HandleIncident recipientId={recipientId} incidentId={incidentId} />
    </Box>
  ) : null
}
